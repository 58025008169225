import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { client as apolloClient } from '../graphql/apollo-client';
import {
    AI_INTERVIEW_REQUEST,
    AIInterviewRequest,
    CREATE_AI_INTERVIEW_REQUEST
} from '../graphql/queries/ai-interview-requests';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

export const PersonAIInterview: React.FC<{ personId: string; jobId: string }> = ({ personId, jobId }) => {
    const { userPermissions } = useSession();
    const canView = hasRole(userPermissions, 'astra_access');
    const { data, loading, refetch } = useQuery<
        { interviewRequests: AIInterviewRequest[] },
        { personId: string; jobId: string }
    >(AI_INTERVIEW_REQUEST, {
        skip: !canView,
        variables: { personId, jobId }
    });
    const [createInterview, { loading: creating }] = useMutation<
        { createAIInterviewRequest: string },
        { personId: string; jobId: string }
    >(CREATE_AI_INTERVIEW_REQUEST, { client: apolloClient('astra_access') });
    const { setSnackbar } = useSnackbar();

    const handleCreateInterview = async () => {
        try {
            setSnackbar('Requesting AI Interview...');
            await createInterview({ variables: { personId, jobId } });
            setSnackbar('AI Interview Requested');
            refetch();
        } catch {
            setSnackbar('Failed to request AI Interview');
        }
    };

    if (!canView || loading) {
        return null;
    }

    const request = data?.interviewRequests?.[0];
    const createButton = !request ? (
        <Button onClick={handleCreateInterview} disabled={creating}>
            Request AI Interview
        </Button>
    ) : null;
    const interviewUrl = request?.interviewUrl;

    const content = interviewUrl ? (
        <Button href={interviewUrl} target="_blank" rel="noreferrer">
            AI Interview Link
        </Button>
    ) : (
        createButton
    );

    return <div>{content}</div>;
};
