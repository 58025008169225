import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { Check, Delete, Schedule, Send } from '@material-ui/icons';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { hasRole } from 'shared/models/user';
import { fullDateTime } from '../common/timestamp';

import { client as apolloClient } from '../graphql/apollo-client';
import { DELETE_INVOICE_SEND, Invoice, SEND_INVOICE } from '../graphql/queries/billing';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useTimepicker } from '../hooks/use-time-picker';

const styles = (theme: Theme) => css`
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 10px;
    font-weight: 500;
    cursor: pointer;

    .no-sends {
        color: ${theme.palette.warning.main};
    }

    .sent {
        display: flex;
        align-items: center;
        gap: 5px;

        svg.MuiSvgIcon-root {
            font-size: 18px;
        }
    }
`;

const dialogStyles = css`
    .MuiDialogContent-root {
        padding: 0 8px;
    }

    .MuiListItem-container {
        min-width: 480px;
    }

    .not-active {
        cursor: default;
    }
`;

export const InvoiceSendsInfo: React.FC<{ invoice: Invoice; onChange: () => void }> = ({ invoice, onChange }) => {
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { userPermissions } = useSession();
    const { getCustomTime } = useTimepicker();
    const { getConfirmation } = useModal();
    const [sendInvoice] = useMutation<{}, { invoiceId: string; sendAt: number }>(SEND_INVOICE, {
        client: apolloClient('billing_admin')
    });
    const [deleteInvoiceSend] = useMutation<{}, { id: string }>(DELETE_INVOICE_SEND, {
        client: apolloClient('billing_admin')
    });

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleDeleteScheduled = (id: string) => () => {
        getConfirmation(
            async () => {
                await deleteInvoiceSend({ variables: { id } });
                onChange();
            },
            'Are you sure you want to delete this scheduled send?',
            'Delete Scheduled Send'
        );
    };

    const handleScheduleSend = async (time: number) => {
        await sendInvoice({ variables: { invoiceId: invoice.id, sendAt: time } });
        onChange();
    };

    const handleScheduleClick = () => {
        getCustomTime({ buttonLabel: 'Schedule', onSelect: handleScheduleSend });
    };

    const noSends = invoice.sends.length === 0;
    const noSendsMessage = noSends ? <div className="no-sends">Not Sent or Scheduled</div> : null;

    const sortedSends = orderBy(
        invoice.sends,
        [(s) => (!s.sentAt ? 1 : 0), (s) => s.requestedAt ?? 0, (s) => s.sentAt ?? 0],
        ['desc', 'desc', 'desc']
    );
    const lastSend = sortedSends[0];

    const sentCount = invoice.sends.filter((s) => s.sentAt).length;
    const scheduledSends = invoice.sends.filter((s) => !s.sentAt);
    const nextScheduledDate = scheduledSends.length
        ? orderBy(scheduledSends, [(s) => s.requestedAt ?? 0], ['asc'])[0].requestedAt
        : null;

    const tooltipContent = [
        sentCount > 0 ? `Sent ${sentCount} ${sentCount === 1 ? 'time' : 'times'}` : null,
        nextScheduledDate ? `Next scheduled for ${fullDateTime(nextScheduledDate)}` : null
    ]
        .filter(Boolean)
        .join(', ');

    const mainInfo = !noSends ? (
        <Tooltip title={tooltipContent}>
            <div className="sent">
                {lastSend.sentAt ? <Send /> : <Schedule />}
                {fullDateTime(lastSend.sentAt ?? lastSend.requestedAt)}
            </div>
        </Tooltip>
    ) : null;

    let dialog = null;
    if (dialogOpen) {
        const sends = sortedSends.map((send) => {
            const time = send.sentAt
                ? fullDateTime(send.sentAt)
                : send.requestedAt
                  ? fullDateTime(send.requestedAt)
                  : null;
            const icon = send.sentAt ? (
                <Tooltip title="Sent">
                    <Check />
                </Tooltip>
            ) : (
                <Tooltip title="Scheduled">
                    <Schedule />
                </Tooltip>
            );
            const deleteScheduled =
                !send.sentAt && hasRole(userPermissions, 'billing_admin') ? (
                    <IconButton onClick={handleDeleteScheduled(send.id)}>
                        <Tooltip title="Delete">
                            <Delete />
                        </Tooltip>
                    </IconButton>
                ) : null;
            return (
                <ListItem key={send.id} className="send">
                    <ListItemText primary={time} />
                    <ListItemSecondaryAction>
                        <IconButton className="not-active">{icon}</IconButton>
                        {deleteScheduled}
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });

        const scheduleButton =
            hasRole(userPermissions, 'billing_admin') && !nextScheduledDate ? (
                <Button onClick={handleScheduleClick}>Schedule Send</Button>
            ) : null;

        dialog = (
            <Dialog open={dialogOpen} onClose={handleClose} css={dialogStyles}>
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Invoice Sends
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <List>{sends}</List>
                </DialogContent>
                <DialogActions>
                    {scheduleButton}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <>
            <div css={styles(theme)} onClick={handleClick}>
                {noSendsMessage}
                {mainInfo}
            </div>
            {dialog}
        </>
    );
};
