import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';

import { TextField } from '../core-ui/form-fields';
import { useSnackbar } from '../hooks/use-snackbar';
import { OutreachPreviewButton } from './outreach-preview-button';

const styles = css`
    .MuiDialogContent-root {
        width: 640px;

        .MuiFormControl-root {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .MuiDialogActions-root {
        display: flex;
        justify-content: space-between;
    }
`;

interface AddCandidatesDialogProps {
    jobId: string;
    personIds: string[] | null;
    onClose: () => void;
    onAdd: () => Promise<void>;
}

export const AddCandidatesDialog: React.FC<AddCandidatesDialogProps> = ({ jobId, personIds, onClose, onAdd }) => {
    const [adding, setAdding] = React.useState(false);
    const [candidateOutreachContext, setCandidateOutreachContext] = React.useState<string | null>(null);
    const { setSnackbar } = useSnackbar();

    const handleCandidateOutreachContextChange = (value: string) => {
        setCandidateOutreachContext(value);
    };

    const handleAddConfirmed = async () => {
        try {
            setAdding(true);
            setSnackbar('Adding candidates...');
            await onAdd();
            setSnackbar('Candidates added');
            setCandidateOutreachContext(null);
            onClose();
        } finally {
            setAdding(false);
        }
    };

    if (!personIds) return null;

    return (
        <Dialog open={!!personIds} css={styles} maxWidth="md">
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Add {personIds.length} candidates
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div>
                    <Typography variant="body1" component="div">
                        {personIds.length} candidates will be added to the job, and will get an email next time an
                        outreach is sent. Add any optional context for the outreach here.
                    </Typography>
                    <TextField
                        label="Outreach context"
                        value={candidateOutreachContext}
                        onChange={handleCandidateOutreachContextChange}
                        disabled={adding}
                        multiline={true}
                        helperText="This will be used in outreach generation."
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <div>
                    <OutreachPreviewButton
                        jobId={jobId}
                        personIds={personIds}
                        overrides={{ searchContext: candidateOutreachContext }}
                        disabled={adding}
                    />
                </div>
                <div>
                    <Button onClick={onClose} disabled={adding}>
                        Cancel
                    </Button>
                    <Button onClick={handleAddConfirmed} disabled={adding}>
                        Add
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
