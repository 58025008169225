import { useQuery } from '@apollo/client';
import {
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { generateUniqueId } from '../core-ui/form-fields';

import { CLIENT_CUSTOMERS, Customer } from '../graphql/queries/billing';
import { BillCustomerForm } from './bill-customer-form';

interface BillCustomerSelectorProps {
    clientId: string;
    value: string;
    onSelect: (customerId: string) => void;
    errorText?: string;
    disabled?: boolean;
}

export const BillCustomerSelector: React.FC<BillCustomerSelectorProps> = ({
    clientId,
    value,
    onSelect,
    errorText,
    disabled: propDisabled
}) => {
    const { data, loading, refetch } = useQuery<{ customers: Customer[] }, { clientId: string }>(CLIENT_CUSTOMERS, {
        variables: { clientId }
    });
    const [disabled, setDisabled] = useState(false);
    const [addCustomerFormOpen, setAddCustomerFormOpen] = useState(false);
    const [editCustomerFormData, setEditCustomerFormData] = useState<Customer | undefined>(undefined);
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectLabelId] = useState(generateUniqueId('select-customer-label-'));

    useEffect(() => {
        if (!value && data?.customers.length > 0) {
            onSelect(data.customers[0].id);
        }
    }, [value, data]);

    const handleOpenAddCustomerForm = () => {
        setDisabled(true);
        setAddCustomerFormOpen(true);
        setSelectOpen(false);
    };

    const handleEditCustomer = (customer: Customer) => () => {
        setEditCustomerFormData(customer);
        setSelectOpen(false);
    };

    const handleCloseCustomerForm = async (createdId: string | null) => {
        if (createdId) {
            await refetch();
            onSelect(createdId);
        }
        setDisabled(false);
        setAddCustomerFormOpen(false);
        setEditCustomerFormData(undefined);
    };

    const handleSelectCustomer = (event: React.ChangeEvent<{ value: string }>) => {
        onSelect(event.target.value);
    };

    const handleOpenSelect = () => setSelectOpen(true);
    const handleCloseSelect = () => setSelectOpen(false);

    const renderValue = (selected: string) => {
        const customer = data?.customers.find((c) => c.id === selected);
        return customer ? customer.name : selected;
    };

    const addCustomerMenuOpt =
        disabled || loading || propDisabled ? null : (
            <MenuItem onMouseDown={handleOpenAddCustomerForm}>Add Customer</MenuItem>
        );
    const divider = data?.customers.length > 0 ? <Divider /> : null;
    const options = data?.customers.map((c) => (
        <MenuItem key={c.id} value={c.id}>
            <ListItemText>{c.name ?? c.companyName}</ListItemText>
            <ListItemSecondaryAction>
                <IconButton size="small" onClick={handleEditCustomer(c)}>
                    <Edit fontSize="small" />
                </IconButton>
            </ListItemSecondaryAction>
        </MenuItem>
    ));
    const selectCustomer = (
        <FormControl fullWidth={true}>
            <InputLabel id={selectLabelId}>Bill Customer</InputLabel>
            <Select
                labelId={selectLabelId}
                value={value}
                onChange={handleSelectCustomer}
                disabled={disabled || loading || propDisabled}
                renderValue={renderValue}
                open={selectOpen}
                onOpen={handleOpenSelect}
                onClose={handleCloseSelect}
                error={!!errorText}
            >
                {options}
                {divider}
                {addCustomerMenuOpt}
            </Select>
            <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
        </FormControl>
    );

    const customerForm = addCustomerFormOpen ? (
        <BillCustomerForm open={addCustomerFormOpen} clientId={clientId} onClose={handleCloseCustomerForm} />
    ) : editCustomerFormData ? (
        <BillCustomerForm
            open={true}
            clientId={clientId}
            onClose={handleCloseCustomerForm}
            customer={editCustomerFormData}
        />
    ) : null;

    return (
        <>
            {selectCustomer}
            {customerForm}
        </>
    );
};
