import { useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Theme, useTheme } from '@material-ui/core';
import { Add, ChevronRight } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { orderBy, times } from 'lodash';
import moment from 'moment';
import React from 'react';

import { InterviewKind } from 'shared/models/interview';
import { hasRole } from 'shared/models/user';

import { Interview, Interviews, INTERVIEWS, INTERVIEWS_LATEST_MODIFICATION } from '../graphql/queries/interviews';
import { useSession } from '../hooks/use-session';
import { PersonAIInterview } from './person-ai-interview';
import { PersonInterview } from './person-interview';

const styles = (theme: Theme) => css`
    .interviews-nav {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        text-transform: uppercase;
        white-space: nowrap;
        margin: 4px 0 25px;

        .select-tab {
            padding: 2px 20px;
            margin: 0;
            font-weight: 500;
            cursor: pointer;
            color: ${theme.palette.text.secondary};
            border-right: thin solid ${theme.palette.divider};
            display: inline-flex;

            &.active {
                color: ${theme.palette.text.primary};
                font-weight: 600;
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }

    .interviews {
        margin: -10px -10px;
        display: flex;
        flex-wrap: wrap;
    }

    .job-interviews {
        margin-bottom: 40px;
    }

    .interviews-job-title {
        margin-bottom: 12px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
    }

    .interview {
        width: 160px;
        height: 200px;
        margin: 10px 10px;
        border-radius: ${theme.shape.borderRadius}px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &.add {
            .MuiButton-root {
                height: 100%;

                .MuiButton-label {
                    height: 100%;

                    .MuiSvgIcon-root {
                        font-size: 96px;
                        color: white;
                    }
                }
            }
        }
    }
`;

const skeletonsCount = 3;
const rocketInterviewKinds: InterviewKind[] = ['Rocket Screen'];
const clientInterviewKinds: InterviewKind[] = ['Client First Round', 'Client Middle Round', 'Client Final Round'];
const allInterviewKinds: InterviewKind[] = [...rocketInterviewKinds, ...clientInterviewKinds];

export const PersonInterviews: React.FC<{ personId: string; jobId: string }> = ({ personId, jobId }) => {
    const theme = useTheme();
    const [allJobs, setAllJobs] = React.useState(false);
    const [adding, setAdding] = React.useState(false);
    const { data, refetch } = useQuery<Interviews, { personId: string; jobId: string }>(INTERVIEWS, {
        variables: { personId, jobId: jobId ?? '' }
    });
    const { data: interviewLatestModifiedData } = useSubscription<
        { interviews: Array<{ modifiedAt: number }> },
        { personId: string }
    >(INTERVIEWS_LATEST_MODIFICATION, { variables: { personId } });
    const { user, userPermissions } = useSession();

    React.useEffect(() => {
        if (interviewLatestModifiedData?.interviews?.[0]?.modifiedAt) {
            refetch();
        }
    }, [interviewLatestModifiedData?.interviews?.[0]?.modifiedAt]);

    const handleJobTabClick = (all: boolean) => () => setAllJobs(all);
    const handleAddInterview = () => setAdding(true);
    const handleCancelAdd = () => setAdding(false);
    const handleUpdated = async () => {
        await refetch();
        setAdding(false);
    };

    const jobTabs = !!jobId ? (
        <div className="job-select">
            <div className={`select-tab ${allJobs ? 'active' : ''}`} onClick={handleJobTabClick(true)}>
                All Jobs
            </div>
            <div className={`select-tab ${!allJobs ? 'active' : ''}`} onClick={handleJobTabClick(false)}>
                Current Job
            </div>
        </div>
    ) : null;

    let content;
    if (!data?.interviews) {
        const loaders = times(skeletonsCount).map((i) => (
            <Skeleton className="interview skeleton" variant="rect" key={i} />
        ));
        content = <div className="interviews">{loaders}</div>;
    } else {
        const groupedInterviews: Array<{
            job: { id: string; title: string; client: { name: string; id: string } };
            interviews: Interview[];
        }> = [];
        data.interviews.forEach((interview) => {
            const job = groupedInterviews.find((g) => g.job.id === interview.job.id);
            if (job) {
                job.interviews.push(interview);
            } else {
                groupedInterviews.push({
                    interviews: [interview],
                    job: interview.job
                });
            }
        });
        if (jobId && !groupedInterviews.find((g) => g.job.id === jobId)) {
            groupedInterviews.push({
                interviews: [],
                job: data?.job
            });
        }
        const sortedGroupedInterviews = orderBy(
            groupedInterviews,
            [(g) => jobId && g.job.id === jobId, (g) => g.job.client.name, (g) => g.job.title],
            ['desc', 'asc', 'asc']
        );

        const filteredGroups =
            allJobs || !jobId ? sortedGroupedInterviews : sortedGroupedInterviews.filter((g) => g.job.id === jobId);

        content = filteredGroups.map(({ job, interviews: unsorted }) => {
            const interviews = orderBy(
                unsorted,
                [(i) => allInterviewKinds.indexOf(i.kind), (i) => i.startTime],
                ['asc', 'asc']
            );
            const title =
                job.id === jobId ? null : (
                    <div className="interviews-job-title">
                        {job.client.name} <ChevronRight /> {job.title}
                    </div>
                );
            const list = interviews.map((interview) => {
                const validKinds: InterviewKind[] =
                    interview.kind === 'Rocket Screen' ? rocketInterviewKinds : clientInterviewKinds;
                return (
                    <PersonInterview
                        data={interview}
                        key={interview.id}
                        onSave={handleUpdated}
                        onDelete={handleUpdated}
                        validKinds={validKinds}
                    />
                );
            });
            const canAdd =
                jobId &&
                job.id === jobId &&
                (hasRole(userPermissions, 'settings_editor') ||
                    data?.candidate?.assignee === user.id ||
                    data?.candidate?.accountManagerId === user.id);
            const addInterviewButton =
                canAdd && !adding ? (
                    <div className="interview add">
                        <Button variant="contained" onClick={handleAddInterview}>
                            <Add />
                        </Button>
                    </div>
                ) : null;
            let newInterviewComponent;
            if (adding) {
                const latestInterviewKindIndex =
                    interviews.length === 0 ? 0 : Math.max(...interviews.map((i) => allInterviewKinds.indexOf(i.kind)));
                const rocketScreenExists = interviews.findIndex((i) => i.kind === 'Rocket Screen') !== -1;
                const newInterviewValidKinds = allInterviewKinds
                    .slice(latestInterviewKindIndex)
                    .filter((k) => !rocketScreenExists || k !== 'Rocket Screen');
                const durationMinutes = 30;
                const startTime = moment().add(1, 'hour').startOf('hour').valueOf();
                const endTime = moment(startTime).add(durationMinutes, 'minutes').valueOf();
                const kind = newInterviewValidKinds[0];
                const newInterviewData = {
                    endTime,
                    jobId,
                    kind,
                    personId,
                    startTime
                };
                newInterviewComponent = (
                    <PersonInterview
                        data={newInterviewData}
                        onSave={handleUpdated}
                        onDelete={handleCancelAdd}
                        validKinds={newInterviewValidKinds}
                    />
                );
            }
            return (
                <div className="job-interviews" key={job.id}>
                    {title}
                    <div className="interviews">
                        {list}
                        {addInterviewButton}
                        {newInterviewComponent}
                    </div>
                </div>
            );
        });
    }

    return (
        <div css={styles(theme)}>
            <div className="interviews-nav">{jobTabs}</div>
            {content}
            <PersonAIInterview personId={personId} jobId={jobId} />
        </div>
    );
};
