import { useLazyQuery, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { OrderedMap } from 'immutable';
import { times } from 'lodash';
import { debounce } from 'lodash';
import React from 'react';
import Waypoint from 'react-waypoint';

import {
    Candidate,
    STAGE_CANDIDATES,
    StageCandidatesVars,
    StageCountsRecordWithFunnel
} from '../graphql/queries/candidates-board';
import { useSlides } from '../hooks/use-candidate-slides';
import { useCandidatesBoard } from '../hooks/use-candidates-board';
import { usePrevious } from '../hooks/use-previous';
import { KanbanCard, KanbanCardSkeleton } from './kanban-card';
import { StageFunnelMetrics } from './stage-funnel-metrics';

interface KanbanColumnProps {
    stageCounts: StageCountsRecordWithFunnel;
}

const styles = css`
    flex: 0 0 auto;
    width: 240px;
    margin: 15px;
    display: flex;
    flex-direction: column;

    &:first-child {
        margin-left: 25px;
    }

    &:last-child {
        margin-right: 25px;
    }

    .stage-header {
        font-size: 0.96rem;
        flex: 0 0 auto;
        margin-bottom: 10px;

        .title {
            font-weight: 600;
        }
        .subtitle {
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .candidates-list {
        overflow-y: auto;
        flex: 1 1 auto;
        padding-right: 8px;
        padding-top: 10px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: $lightBg2Color;
            padding-top: 6px;
        }

        &::-webkit-scrollbar-button {
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: inherit;
            border-radius: 2px;
        }

        &:hover::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.3);
        }
    }
`;

const batchSize = 10;
const debounceTime = 250;

export const KanbanColumn: React.FC<KanbanColumnProps> = ({ stageCounts }) => {
    const { setList } = useSlides();
    const { assignee, jobIds, disqualified } = useCandidatesBoard();
    const prevCounts = usePrevious(stageCounts);
    const [candidates, setCandidates] = React.useState<OrderedMap<string, Candidate>>(OrderedMap());
    const [reloading, setReloading] = React.useState(false);

    const [lastRequestedIndex, setLastRequestedIndex] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const commonVars: Omit<StageCandidatesVars, 'minSortOrder' | 'maxSortOrder'> = {
        assignee,
        disqualified,
        excludeArchivedJobs: !!assignee && !jobIds,
        jobIds,
        stageId: stageCounts.id
    };

    const { data: initialData, loading: initialLoading } = useQuery<{ candidates: Candidate[] }, StageCandidatesVars>(
        STAGE_CANDIDATES,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                ...commonVars,
                maxSortOrder: Math.min(batchSize, stageCounts.candidatesCount),
                minSortOrder: 1
            }
        }
    );

    const [fetchCandidates, { data, loading: refetchLoading }] = useLazyQuery<
        { candidates: Candidate[] },
        StageCandidatesVars
    >(STAGE_CANDIDATES, { fetchPolicy: 'network-only' });

    const loading = initialLoading || refetchLoading || isLoading;

    const updateCandidates = (initialRecords: OrderedMap<string, Candidate>, newRecords: Candidate[]) => {
        let updatedCandidates = initialRecords;
        for (const candidate of newRecords) {
            if (candidate.stage.id === stageCounts.id) {
                updatedCandidates = updatedCandidates.set(`${candidate.personId}-${candidate.jobId}`, candidate);
            }
        }
        setCandidates(updatedCandidates);
    };

    React.useEffect(() => {
        if (initialData) {
            updateCandidates(OrderedMap(), initialData.candidates);
            setLastRequestedIndex(Math.min(batchSize, stageCounts.candidatesCount));
        }
    }, [initialData]);

    React.useEffect(() => {
        if (data) {
            updateCandidates(reloading ? OrderedMap() : candidates, data.candidates);
            setReloading(false);
            setIsLoading(false);
        }
    }, [data]);

    const fetchNextBatch = React.useCallback(() => {
        const nextStartIndex = lastRequestedIndex + 1;
        const nextEndIndex = Math.min(lastRequestedIndex + batchSize, stageCounts.candidatesCount);

        if (nextStartIndex > stageCounts.candidatesCount || isLoading) {
            return;
        }

        setIsLoading(true);
        setLastRequestedIndex(nextEndIndex);

        fetchCandidates({
            variables: {
                ...commonVars,
                maxSortOrder: nextEndIndex,
                minSortOrder: nextStartIndex
            }
        });
    }, [lastRequestedIndex, stageCounts.candidatesCount, isLoading, fetchCandidates, commonVars]);

    React.useEffect(() => {
        if (
            prevCounts &&
            (prevCounts.candidatesCount !== stageCounts.candidatesCount ||
                prevCounts.pendingEmailsCount !== stageCounts.pendingEmailsCount ||
                prevCounts.interviewsCount !== stageCounts.interviewsCount)
        ) {
            setReloading(true);
            setLastRequestedIndex(0);
            setIsLoading(false);

            fetchCandidates({
                variables: {
                    ...commonVars,
                    maxSortOrder: Math.min(batchSize, stageCounts.candidatesCount),
                    minSortOrder: 1
                }
            });
        }
    }, [stageCounts]);

    const handleWaypointPositionChanged = React.useCallback(
        debounce(
            (args: { currentPosition: string }) => {
                if (!loading && (args.currentPosition === 'inside' || args.currentPosition === 'above')) {
                    fetchNextBatch();
                }
            },
            debounceTime,
            { leading: true, trailing: false }
        ),
        [loading, fetchNextBatch]
    );

    const handleCandidateClick = (selected: Candidate) => () => {
        const list = candidates
            .valueSeq()
            .map((c) => ({ personId: c.personId, jobId: c.jobId }))
            .toArray();
        setList(list, { personId: selected.personId, jobId: selected.jobId });
    };

    const candidateCards = candidates
        .valueSeq()
        .map((c) => <KanbanCard key={`${c.personId}-${c.jobId}`} candidate={c} onClick={handleCandidateClick(c)} />);

    const skeletonCards =
        candidates.size < stageCounts.candidatesCount
            ? times(Math.min(batchSize, stageCounts.candidatesCount - candidates.size), (i) => (
                  <KanbanCardSkeleton key={i} />
              ))
            : null;

    const loadingWaypoint =
        !loading && candidates.size < stageCounts.candidatesCount ? (
            <Waypoint onPositionChange={handleWaypointPositionChanged} />
        ) : null;

    return (
        <div css={styles}>
            <div className="stage-header">
                <span className="title">{stageCounts.label}</span>{' '}
                <span className="subtitle">({stageCounts.candidatesCount})</span>
            </div>
            <StageFunnelMetrics metrics={stageCounts} />
            <div className="candidates-list">
                {candidateCards}
                {loadingWaypoint}
                {skeletonCards}
            </div>
        </div>
    );
};
