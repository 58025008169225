import { useLazyQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { FileDownloadLink } from '../core-ui/file-download-link';
import { client as apolloClient } from '../graphql/apollo-client';
import { Invoice as InvoiceData, INVOICE_DETAILS, RESYNC_INVOICE } from '../graphql/queries/billing';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { InvoiceDetails } from './invoice-details';
import { InvoiceSendsInfo } from './invoice-sends-info';

interface InvoiceDetailsDialogProps {
    open: boolean;
    onClose: () => void;
    invoice?: InvoiceData;
}

const styles = css`
    .MuiDialogActions-root {
        display: flex;
        justify-content: space-between;

        .actions {
            display: flex;
            gap: 8px;
        }
    }
`;

export const InvoiceDetailsDialog: React.FC<InvoiceDetailsDialogProps> = ({ open, onClose, invoice }) => {
    if (!invoice) return null;
    const { userPermissions } = useSession();
    const { setSnackbar } = useSnackbar();
    const [resyncInvoice, { loading: updating }] = useMutation<{}, { id: string }>(RESYNC_INVOICE, {
        client: apolloClient('billing_admin')
    });
    const [fetchInvoiceDetails, { data, loading: refetching }] = useLazyQuery<{ invoice: InvoiceData }, { id: string }>(
        INVOICE_DETAILS,
        {
            fetchPolicy: 'network-only',
            variables: { id: invoice.id }
        }
    );

    const handleResyncInvoice = async () => {
        setSnackbar('Re-syncing invoice...');
        await resyncInvoice({ variables: { id: invoice.id } });
        await fetchInvoiceDetails();
        setSnackbar('Invoice re-synced successfully');
    };

    const viewPdfButton = invoice.pdf ? (
        <FileDownloadLink path={invoice.pdf} filename={invoice.pdf?.split('/')?.pop()}>
            <Button>View PDF</Button>
        </FileDownloadLink>
    ) : null;

    const resyncButton = hasRole(userPermissions, 'billing_admin') ? (
        <Button disabled={updating || refetching} onClick={handleResyncInvoice}>
            {updating || refetching ? 'Re-syncing...' : 'Resync Invoice'}
        </Button>
    ) : null;

    const invoiceData = data?.invoice ?? invoice;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" css={styles}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Invoice Details
                </Typography>
            </DialogTitle>
            <DialogContent>
                <InvoiceDetails data={invoiceData} />
            </DialogContent>
            <DialogActions>
                <InvoiceSendsInfo invoice={invoiceData} onChange={fetchInvoiceDetails} />
                <div className="actions">
                    {resyncButton}
                    {viewPdfButton}
                    <Button onClick={onClose}>Close</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
