import { useLazyQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import React from 'react';

import { SampleOutreachData } from 'shared/models/sequence';

import { GET_SAMPLE_OUTREACH_PREVIEW } from '../graphql/queries/sequences';
import { useModal } from '../hooks/use-modal';
import { PreviewLLMOutreachDialog } from './preview-llm-outreach-dialog';

export const OutreachPreviewButton: React.FC<{
    jobId: string;
    disabled?: boolean;
    personIds?: string[];
    overrides?: { jobContext?: string; searchContext?: string };
}> = ({ jobId, disabled, personIds, overrides }) => {
    const [getSampleOutreach, { data, loading }] = useLazyQuery<
        { sampleOutreach: SampleOutreachData },
        { args: { jobId: string; personId?: string; overrides?: { jobContext?: string; searchContext?: string } } }
    >(GET_SAMPLE_OUTREACH_PREVIEW);
    const { showLoading, hideLoading, setAlert } = useModal();
    const [previewing, setPreviewing] = React.useState(false);

    const handleClick = async () => {
        try {
            showLoading();
            const result = await getSampleOutreach({
                fetchPolicy: 'network-only',
                variables: { args: { jobId, personId: personIds?.[0], overrides } }
            });
            if (!result.data?.sampleOutreach) {
                throw new Error('No sample outreach data');
            }
            setPreviewing(true);
        } catch (e) {
            setAlert('Error', 'Error generating outreach preview');
        } finally {
            hideLoading();
        }
    };

    const handleClose = () => {
        setPreviewing(false);
    };

    const preview =
        previewing && data?.sampleOutreach ? (
            <PreviewLLMOutreachDialog
                data={data?.sampleOutreach}
                open={previewing}
                onClose={handleClose}
                onTryAnother={!personIds?.length ? handleClick : undefined}
            />
        ) : null;

    return (
        <>
            <Button disabled={disabled || loading} onClick={handleClick}>
                Preview Outreach
            </Button>
            {preview}
        </>
    );
};
