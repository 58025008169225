import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';

import { NoteKind } from 'shared/models/note';

import { useSubscription } from '@apollo/client';
import { fetchPersonDetails, fetchUsers, updateUserPrefs } from '../actions';
import { Person } from '../components/person';
import { Spinner } from '../core-ui/spinner';
import { PERSON_PROFILE_VIEWED_AT } from '../graphql/queries/person';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { Candidate as CandidateData, Client, Job, Preferences } from '../state';

interface PersonProps {
    id: string;
    candidate?: CandidateData;
    job?: Job;
    client?: Client;
    tab?: string;
    jobNotesOnly?: boolean;
    notesKind?: NoteKind;
    menuButton?: JSX.Element;
}

export const PersonProfile: React.FC<PersonProps> = (props) => {
    const { id, tab, candidate, job, jobNotesOnly, menuButton, notesKind, client } = props;
    const dispatch = useReduxDispatch();
    const listsState = useReduxState((state) => state.listsState);
    const persons = useReduxState((state) => state.personsDetails);
    const selectedProfileTab = useReduxState((state) => state.preferences.selectedProfileTab);
    const { data: profileViewedAtData } = useSubscription<{ profile: { viewedAt: number } }, { personId: string }>(
        PERSON_PROFILE_VIEWED_AT,
        {
            variables: { personId: id }
        }
    );

    useEffect(() => {
        dispatch(fetchPersonDetails(id));
    }, [id]);

    useEffect(() => {
        if (profileViewedAtData?.profile?.viewedAt) {
            dispatch(fetchPersonDetails(id));
        }
    }, [profileViewedAtData?.profile?.viewedAt]);

    useEffect(() => {
        if (listsState.get('users') !== 'initialized') {
            dispatch(fetchUsers());
        }
    }, []);

    const handleUpdateUserPrefs = (prefs: Partial<Preferences>) => {
        dispatch(updateUserPrefs(prefs));
    };

    if (!persons.list || !persons.list.get(id) || listsState.get('users') !== 'initialized') {
        return <Spinner />;
    } else {
        const person = persons.list.get(id);
        return (
            <DocumentTitle title={`Profile - ${person.person.name.full}`}>
                <Person
                    details={person}
                    candidate={candidate}
                    job={job}
                    client={client}
                    updateUserPrefs={handleUpdateUserPrefs}
                    selectedProfileTab={tab || selectedProfileTab}
                    jobNotesOnly={jobNotesOnly}
                    notesKind={notesKind}
                    menuButton={menuButton}
                />
            </DocumentTitle>
        );
    }
};
