import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Checkbox, Tooltip } from '@material-ui/core';
import React from 'react';

import { colors, getScoreColor } from '../common/score-ratings';
import { JobCPCandidate } from '../graphql/queries/job';
import { CREATE_PROFILE_SCORE, DELETE_PROFILE_SCORE } from '../graphql/queries/profile-score';
import { CREATE_SOURCINGS, Sourcing } from '../graphql/queries/sourcing';
import { useAIProfileScoreDetails } from '../hooks/use-ai-profile-score-details';
import { useSlides } from '../hooks/use-candidate-slides';
import { useModal } from '../hooks/use-modal';
import { AdornmentGenerator, useProfileAdornments } from '../hooks/use-profile-adornment';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { AddCandidatesDialog } from './add-candidates-dialog';
import { AIProfileScoreExplanation } from './ai-profile-score-explanation';
import { DataPanel, DataPanelColumn } from './data-panel';
import { StarRating } from './star-rating';

const styles = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    .MuiTableCell-root {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .add-reject-buttons {
        margin-left: 15px;
        display: flex;
        gap: 10px;
    }
`;

type Column = 'select' | 'name' | 'score' | 'assignee' | 'job' | 'stage';

const sourcingSubSource = 'job-high-scores-list';

export const JobCPCandidatesTable: React.FC<{ jobId: string; candidates: JobCPCandidate[]; refetch: () => void }> = ({
    jobId,
    candidates,
    refetch
}) => {
    const [createScore, { loading: creating }] = useMutation<
        { score: { id: string } },
        { personId: string; jobId: string; score: number }
    >(CREATE_PROFILE_SCORE);
    const [deleteScore, { loading: deleting }] = useMutation<{}, { id: string }>(DELETE_PROFILE_SCORE);
    const [createSourcings] = useMutation<{}, { records: Sourcing[] }>(CREATE_SOURCINGS);
    const { setList } = useSlides();
    const { setAdornmentGenerator } = useProfileAdornments();
    const { user } = useSession();
    const { showScoreDetails } = useAIProfileScoreDetails();
    const { setSnackbar } = useSnackbar();
    const [addingCandidates, setAddingCandidates] = React.useState<string[] | null>(null);
    const [selectedPersons, setSelectedPersons] = React.useState<string[]>([]);
    const { getConfirmation } = useModal();

    const handleDeleteScore = (scoreId: string) => async () => {
        setSnackbar('Deleting score...');
        await deleteScore({ variables: { id: scoreId } });
        refetch();
        setSnackbar('Score deleted');
    };

    const handleCreateScore = (personId: string) => async (rating: number) => {
        setSnackbar('Creating score...');
        const result = await createScore({ variables: { personId, jobId, score: rating } });
        refetch();
        setSnackbar(
            'Score created',
            <Button style={{ color: 'white' }} onClick={handleDeleteScore(result.data.score.id)}>
                Undo
            </Button>
        );
    };

    const handleScoreClick = (score: JobCPCandidate['profileScore']) => (e: React.MouseEvent) => {
        e.stopPropagation();
        showScoreDetails(score);
    };

    const handleCancelAdd = () => {
        setAddingCandidates(null);
    };

    const handleAddConfirmed = async () => {
        const records: Sourcing[] = addingCandidates.map((personId) => ({
            jobId,
            personId,
            source: 'titan',
            subSource: sourcingSubSource,
            type: 'added'
        }));
        await createSourcings({ variables: { records } });
        setAddingCandidates(null);
        setSelectedPersons([]);
        refetch();
    };

    const handleAddCandidates = (selected: JobCPCandidate[]) => () => {
        setAddingCandidates(selected.map((c) => c.candidate.person.id));
    };

    const handleRejectCandidates = (selected: JobCPCandidate[]) => () => {
        getConfirmation(
            async () => {
                setSnackbar('Rejecting candidates...');
                const records: Sourcing[] = selected.map((c) => ({
                    jobId,
                    personId: c.candidate.person.id,
                    source: 'titan',
                    subSource: sourcingSubSource,
                    type: 'rejected'
                }));
                await createSourcings({ variables: { records } });
                refetch();
                setSnackbar('Candidates rejected');
                setSelectedPersons([]);
            },
            'Are you sure you want to reject these candidates? This will prevent them from showing up in this list for this job',
            'Reject candidates'
        );
    };

    const handleSelectPerson = React.useCallback(
        (id: string) => (_1: React.ChangeEvent, selected: boolean) => {
            setSelectedPersons((prev) => (selected ? prev.concat([id]) : prev.filter((personId) => id !== personId)));
        },
        []
    );

    const renderSummary = React.useCallback(
        (filtered: JobCPCandidate[]) => {
            const filteredAndSelected = filtered.filter((c) => selectedPersons.includes(c.candidate.person.id));
            if (!filteredAndSelected.length) return null;
            return (
                <div className="add-reject-buttons">
                    <Button onClick={handleAddCandidates(filteredAndSelected)} disabled={!!addingCandidates}>
                        Add
                    </Button>
                    <Button onClick={handleRejectCandidates(filteredAndSelected)} disabled={!!addingCandidates}>
                        Reject
                    </Button>
                </div>
            );
        },
        [candidates, selectedPersons]
    );

    const handleSelectAll = (_1: React.ChangeEvent, selected: boolean) => {
        setSelectedPersons(() => (selected ? candidates.map((c) => c.candidate.person.id) : []));
    };

    const getProfileAdornments: AdornmentGenerator = React.useCallback(
        (personId: string) => {
            const score = candidates.find((c) => c.candidate.person.id === personId)?.profileScore;
            if (!score) return {};

            const scoreDetailedView = score ? (
                <div style={{ marginRight: '20px', position: 'relative' }}>
                    <StarRating
                        rating={score.score}
                        tooltip={score.scoreExplanation}
                        htmlColor={getScoreColor(score, user).main}
                        onClick={handleScoreClick(score)}
                        onChange={creating || deleting ? null : handleCreateScore(personId)}
                        hoverHtmlColor={colors.sessionUser.main}
                    />
                </div>
            ) : null;
            const explanationDetailedView = <AIProfileScoreExplanation score={score} />;
            const selectAdornment = (
                <Checkbox checked={selectedPersons.includes(personId)} onChange={handleSelectPerson(personId)} />
            );
            return {
                insertElements: [
                    { slot: 'explanationDetailedView', element: explanationDetailedView },
                    { slot: 'scoreDetailedView', element: scoreDetailedView },
                    { slot: 'select', element: selectAdornment }
                ]
            };
        },
        [candidates, creating, deleting, user, selectedPersons, handleSelectPerson]
    );

    React.useEffect(() => {
        if (candidates.length) {
            setAdornmentGenerator(getProfileAdornments);
        }
        return () => {
            setAdornmentGenerator(null);
        };
    }, [candidates, getProfileAdornments]);

    const handleCandidateClick = (candidate: JobCPCandidate['candidate']) => (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const list = candidates.map((c) => ({
            personId: c.candidate.person.id,
            selectedTab: 'profile'
        }));
        setList(list, { personId: candidate.person.id, selectedTab: 'profile' });
    };

    const getCellValue = (column: Column) => (candidate: JobCPCandidate) => {
        switch (column) {
            case 'assignee':
                return candidate.candidate.assignee.name;
            case 'job':
                return `${candidate.candidate.job.client.name} - ${candidate.candidate.job.title}`;
            case 'name':
                return candidate.candidate.person.name;
            case 'score':
                return candidate.profileScore.score;
            case 'stage':
                return candidate.candidate.stage.label;
        }
    };

    const getCellRenderer = {
        name: (value: string, row: JobCPCandidate) => {
            return (
                <span className="clickable-cell" onClick={handleCandidateClick(row.candidate)}>
                    <a href={`/person/${row.candidate.person.id}`} target="_blank" rel="noreferrer">
                        {value}
                    </a>
                </span>
            );
        },
        score: (value: number, row: JobCPCandidate) => (
            <Tooltip title={row.profileScore.scoreExplanation || ''} onClick={handleScoreClick(row.profileScore)}>
                <span className="clickable-cell">{value}</span>
            </Tooltip>
        ),
        select: (_1: undefined, row: JobCPCandidate) => (
            <Checkbox
                checked={selectedPersons.includes(row.candidate.person.id)}
                onChange={handleSelectPerson(row.candidate.person.id)}
            />
        )
    };

    const selectAllHeader = (
        <Checkbox
            checked={selectedPersons.length === candidates.length}
            indeterminate={selectedPersons.length > 0 && selectedPersons.length < candidates.length}
            onChange={handleSelectAll}
        />
    );

    const columns: Array<DataPanelColumn<Column>> = [
        { id: 'select', label: selectAllHeader },
        { id: 'name', label: 'Name' },
        { id: 'score', label: 'Score' },
        { id: 'assignee', label: 'Assignee' },
        { id: 'job', label: 'Job' },
        { id: 'stage', label: 'Stage' }
    ];

    const addCandidatesDialog = (
        <AddCandidatesDialog
            jobId={jobId}
            personIds={addingCandidates}
            onClose={handleCancelAdd}
            onAdd={handleAddConfirmed}
        />
    );

    return (
        <div css={styles}>
            <DataPanel
                className="cp-candidates-table"
                columns={columns}
                data={candidates}
                getCellValue={getCellValue}
                getCellRenderer={getCellRenderer}
                sortableColumns={['score', 'assignee', 'job', 'stage']}
                filterableColumns={['score', 'assignee', 'job', 'stage']}
                pageSize={100}
                renderSummary={renderSummary}
            />
            {addCandidatesDialog}
        </div>
    );
};
