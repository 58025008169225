import { gql } from '@apollo/client';

export interface Sourcing {
    jobId: string;
    personId: string;
    type: 'added' | 'rejected';
    source: 'titan' | 'linkedin';
    subSource?: string;
    additionalContext?: string;
}

export const CREATE_SOURCINGS = gql`
    mutation CreateSourcings($records: [sourcings_insert_input!]!) {
        insert_sourcings(
            objects: $records
            on_conflict: {
                constraint: sourcings_personId_jobId_userId_key
                update_columns: [type, additionalContext, source, subSource]
            }
        ) {
            affected_rows
        }
    }
`;
