import React, { createContext, FC, ReactNode, useContext } from 'react';

type AdornmentSlot =
    | 'select'
    | 'score'
    | 'explanation'
    | 'explanationDetailedView'
    | 'scoreDetailedView'
    | 'detailProfileActions';
interface ProfileAdornments {
    insertElements?: Array<{
        slot: AdornmentSlot;
        element: ReactNode;
    }>;
    highlightTerms?: Array<{
        terms: string[];
        className: string;
    }>;
}
type AdornmentGenerator = (personId: string, jobId?: string) => ProfileAdornments;

interface ProfileAdornmentsContextType {
    setAdornmentGenerator: (generator: AdornmentGenerator) => void;
    getAdornmentGenerator: () => AdornmentGenerator | null;
    getAdornmentsForPerson: (personId: string, jobId?: string) => ProfileAdornments;
}

const ProfileAdornmentsContext = createContext<ProfileAdornmentsContextType | undefined>(undefined);

interface ProfileAdornmentsProviderProps {
    children: ReactNode;
}

const ProfileAdornmentsProvider: FC<ProfileAdornmentsProviderProps> = ({ children }) => {
    const [adornmentGenerator, setAdornmentGenerator] = React.useState<AdornmentGenerator | null>(null);

    const setter = (generator: AdornmentGenerator) => {
        setAdornmentGenerator(() => generator);
    };

    const getAdornmentGenerator = () => {
        return adornmentGenerator;
    };

    const getAdornmentsForPerson = (personId: string, jobId?: string): ProfileAdornments => {
        return adornmentGenerator?.(personId, jobId) ?? {};
    };

    return (
        <ProfileAdornmentsContext.Provider
            value={{ setAdornmentGenerator: setter, getAdornmentsForPerson, getAdornmentGenerator }}
        >
            {children}
        </ProfileAdornmentsContext.Provider>
    );
};

function useProfileAdornments() {
    const context = useContext(ProfileAdornmentsContext);

    if (!context) {
        throw new Error('useProfileAdornments must be used within a ProfileAdornmentsProvider');
    }

    return context;
}

export { useProfileAdornments, ProfileAdornmentsProvider, AdornmentGenerator, AdornmentSlot };
