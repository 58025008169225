import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import {
    Add,
    ArrowDownward,
    ArrowUpward,
    CheckCircleOutline,
    ErrorOutline,
    ExpandMore,
    GetApp,
    GridOn,
    HourglassEmptyOutlined,
    Info,
    Poll,
    Remove
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import chroma from 'chroma-js';
import { Map, Set } from 'immutable';
import { orderBy, sortBy, times } from 'lodash';
import moment from 'moment';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { fetchScoringEvalData } from '../api';
import { PageDialogLink } from '../common/page-dialog-link';
import { JobEdit } from '../containers/job-edit';
import {
    CandidateScoreEval,
    CREATE_SCORE_EVAL,
    SCORE_EVAL_CANDIDATES,
    SCORE_EVAL_REFERENCE_CANDIDATES,
    SCORE_EVALS,
    ScoreEvalCandidates,
    ScoreEvalReferenceCandidate
} from '../graphql/queries/scoring-evals';
import { useSlides } from '../hooks/use-candidate-slides';
import { useModal } from '../hooks/use-modal';
import { AdornmentGenerator, useProfileAdornments } from '../hooks/use-profile-adornment';
import { getAllStageScores, getMetrics, ScoringMetrics } from '../lib/score-statistics';
import { AIProfileScore } from './ai-profile-score';
import { AIProfileScoreExplanation } from './ai-profile-score-explanation';
import { CandidateScoreEvalForm } from './candidate-score-eval-form';
import { Header } from './header';
import { MetricDisplay } from './metric-display';
import { TableColumnFilterHeader } from './table-column-filter-header';

const skeletonRowsCount = 10;
const rowsPerPage = 50;

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .selectors {
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
            background: white;
            margin-left: 15px;
        }
    }

    .metrics {
        margin-bottom: 20px;
        background: white;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;

        .MuiButton-root.inactive {
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }

        .statistics-header {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            justify-content: space-between;

            .header-actions {
                display: inline-flex;
                gap: 10px;
            }
        }

        .MuiTableCell-root.MuiTableCell-body {
            .v-centered {
                display: inline-flex;
                align-items: center;
                gap: 10px;
            }

            .MuiSvgIcon-root {
                opacity: 0;
                transition: opacity 200ms;
                cursor: pointer;
            }
        }

        .MuiExpansionPanelSummary-root.Mui-expanded {
            min-height: 48px;
            border-bottom: thin solid ${theme.palette.divider};
        }

        .MuiExpansionPanelSummary-content.Mui-expanded {
            margin: 0;
        }

        .MuiExpansionPanelDetails-root {
            padding: 0;
        }

        .stats-table {
            .MuiTableCell-root {
                border-bottom: none;
                padding: 12px 16px;
            }

            tr:hover {
                background: ${theme.palette.action.hover};

                .MuiTableCell-root.MuiTableCell-body .MuiSvgIcon-root {
                    opacity: 1;
                }
            }

            .metric-label {
                width: 280px;
            }

            .metric-value {
                width: 100px;
            }
        }

        .matrix-table {
            .MuiTableCell-root {
                border-right: thin solid ${theme.palette.divider};

                &.diagonal {
                    background: ${theme.palette.action.hover};
                }

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .data-panel {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: space-between;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;
        min-height: 200px;

        .MuiTableContainer-root {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            overflow: auto;

            &::-webkit-scrollbar {
                border-left: thin solid ${theme.palette.divider};
                border-top: thin solid ${theme.palette.divider};
            }
            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }
            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }
        }
    }

    .table {
        tr:hover {
            background: ${theme.palette.action.hover};
        }

        .link-cell {
            color: ${theme.palette.primary.main};
            cursor: pointer;
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }
    }

    .table-footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        border-top: thin solid ${theme.palette.divider};

        .billing-summary {
            margin-left: 15px;
            font-size: 14px;
        }
        .pagination-text {
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }
        .pagination {
            flex: 0 0 auto;
        }
    }
`;

const menuStyles = css`
    .MuiMenuItem-root {
        max-width: 480px;

        .MuiIconButton-root {
            margin-left: auto;
        }

        .MuiTypography-root {
            margin-right: 10px;
        }
    }
`;

type Column =
    | 'Client'
    | 'Job'
    | 'Candidate'
    | 'Progress Rating'
    | 'Score'
    | 'Delta'
    | 'Assignee'
    | 'Stage'
    | 'Last Stage Change';

const filterableColumns: Column[] = ['Client', 'Assignee', 'Stage', 'Progress Rating', 'Score', 'Delta'];

const createNewOpt = 'create-new';
const evalsPollIntervalMs = 60000;
const defaultErrorMargin = 0.5;
// tslint:disable-next-line:no-magic-numbers
const errorMarginOpts = [0, 0.5, 1, 1.5];

function roundToQuarter(num: number) {
    if (isNaN(num)) {
        return num;
    }
    // tslint:disable-next-line:no-magic-numbers
    return Math.round(num * 4) / 4;
}

export const CandidateScoreEvals: React.FC<{}> = () => {
    const theme = useTheme();
    const { setList } = useSlides();
    const { showLoading, hideLoading, setAlert } = useModal();
    const [sortCol, setSortCol] = React.useState<Column>('Progress Rating');
    const [sortAsc, setSortAsc] = React.useState<'asc' | 'desc'>('desc');
    const [scoringSelectMenuAnchor, setScoringSelectMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [errorMargin, setErrorMargin] = React.useState(defaultErrorMargin);
    const [errorMarginMenuAnchor, setErrorMarginMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [page, setPage] = React.useState(0);
    const [filters, setFilters] = React.useState<Map<Column, Set<string | number>>>(Map());
    const [selectedEvalId, setSelectedEvalId] = React.useState<string>(null);
    const [creating, setCreating] = React.useState(false);
    const [viewingEvalId, setViewingEvalId] = React.useState<string | null>(null);
    const [adornmentGeneratorSet, setAdornmentGeneratorSet] = React.useState(false);
    const [metricsView, setMetricsView] = React.useState<'stats' | 'table'>('stats');
    const { setAdornmentGenerator, getAdornmentGenerator } = useProfileAdornments();

    const { data: referenceCandidatesData } = useQuery<{ candidates: ScoreEvalReferenceCandidate[] }>(
        SCORE_EVAL_REFERENCE_CANDIDATES
    );
    const { data: evalsData, refetch } = useQuery<{ evals: CandidateScoreEval[] }>(SCORE_EVALS, {
        pollInterval: evalsPollIntervalMs
    });
    const [createEval] = useMutation<{}, { data: Partial<CandidateScoreEval> }>(CREATE_SCORE_EVAL);
    const [fetchEvalWithScores, { data: evalData }] = useLazyQuery<{ eval: ScoreEvalCandidates }, { id: string }>(
        SCORE_EVAL_CANDIDATES
    );

    const selectedEval = evalsData?.evals?.find((e) => e.id === selectedEvalId);

    React.useEffect(() => {
        if (selectedEval) {
            fetchEvalWithScores({ variables: { id: selectedEvalId } });
        }
    }, [selectedEval]);

    const columns: Column[] = selectedEval
        ? ['Client', 'Job', 'Candidate', 'Progress Rating', 'Score', 'Delta', 'Stage', 'Assignee', 'Last Stage Change']
        : ['Client', 'Job', 'Candidate', 'Progress Rating', 'Stage', 'Assignee', 'Last Stage Change'];

    const valueFunc = (col: Column) => (c: ScoreEvalReferenceCandidate) => {
        switch (col) {
            case 'Client':
                return c.candidate.job.client.name;
            case 'Job':
                return c.candidate.job.title;
            case 'Candidate':
                return c.candidate.person.name;
            case 'Assignee':
                return c.candidate.assignee.name;
            case 'Last Stage Change':
                return c.candidate.lastStageChangedAt;
            case 'Progress Rating':
                return c.progressScore;
            case 'Stage':
                return c.candidate.stage.label;
            case 'Score': {
                const score = evalData?.eval?.profileScores?.find(
                    (p) => p.personId === c.personId && p.jobId === c.jobId
                )?.score;
                return roundToQuarter(score);
            }
            case 'Delta': {
                const score = evalData?.eval?.profileScores?.find(
                    (p) => p.personId === c.personId && p.jobId === c.jobId
                )?.score;
                const progressRating = c.progressScore;
                return score === undefined || progressRating === undefined
                    ? '-'
                    : roundToQuarter(score - progressRating);
            }
            default:
                return null;
        }
    };

    const predictedScoresCandidates: ScoreEvalReferenceCandidate[] = React.useMemo(
        () =>
            !selectedEvalId
                ? null
                : evalData?.eval?.profileScores.map((p) => ({
                      candidate: {
                          assignee: p.candidate.assignee,
                          job: p.candidate.job,
                          lastStageChangedAt: p.candidate.lastStageChangedAt,
                          person: p.candidate.person,
                          stage: p.candidate.stage
                      },
                      jobId: p.jobId,
                      personId: p.personId,
                      progressScore: p.candidate.progressScore
                  })),
        [evalData, selectedEvalId]
    );

    const allCandidates: ScoreEvalReferenceCandidate[] = React.useMemo(
        () => (selectedEvalId ? predictedScoresCandidates : referenceCandidatesData?.candidates),
        [predictedScoresCandidates, referenceCandidatesData, selectedEvalId]
    );

    const allCandidateScores = React.useMemo(
        () =>
            (allCandidates ?? []).map((c) => ({
                jobId: c.jobId,
                personId: c.personId,
                score: c.progressScore
            })),
        [allCandidates]
    );

    const candidates: ScoreEvalReferenceCandidate[] = React.useMemo(
        () => allCandidates?.filter((c) => filters.reduce((res, val, col) => res && val.has(valueFunc(col)(c)), true)),
        [allCandidates, filters]
    );

    const sortedCandidates = React.useMemo(
        () => orderBy(candidates, [valueFunc(sortCol), 'lastStageChangedAt'], [sortAsc, 'desc']),
        [candidates, sortCol, sortAsc]
    );

    const predictedScores = React.useMemo(
        () =>
            !selectedEvalId
                ? null
                : candidates?.map((c) => ({
                      jobId: c.jobId,
                      personId: c.personId,
                      score: evalData?.eval?.profileScores.find((p) => p.personId === c.personId && p.jobId === c.jobId)
                          ?.score
                  })),
        [evalData, selectedEvalId, candidates]
    );
    const progressScores = React.useMemo(
        () =>
            !selectedEvalId
                ? null
                : candidates?.map((c) => ({
                      jobId: c.jobId,
                      personId: c.personId,
                      score: c.progressScore
                  })),
        [candidates, selectedEvalId]
    );

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const handleCreateScoringClick = () => {
        setScoringSelectMenuAnchor(null);
        setCreating(true);
    };

    const handleSelectEval = (id: string) => () => {
        setScoringSelectMenuAnchor(null);
        if (id === null) {
            setSelectedEvalId(null);
            setPage(0);
        } else {
            const scoring = evalsData?.evals?.find((e) => e.id === id);
            if (scoring.completedAt) {
                setPage(0);
                setSelectedEvalId(id);
            } else if (scoring.failedAt) {
                setAlert('Error', 'Scoring has failed, unable to compare results');
            } else {
                setAlert('Please wait', 'Scoring is still in progress, please try again later');
            }
        }
    };

    const handleViewScoringDetails = (id: string) => (e: React.MouseEvent) => {
        e.stopPropagation();
        setScoringSelectMenuAnchor(null);
        setViewingEvalId(id);
    };

    const handleCloseForm = () => {
        setCreating(false);
        setViewingEvalId(null);
    };

    const handleCreateEval = async (data: Partial<CandidateScoreEval>) => {
        setCreating(false);
        await createEval({ variables: { data: { ...data, referenceScoringId: selectedEvalId } } });
        await refetch();
    };

    const handleCloseMenu = () => setScoringSelectMenuAnchor(null);
    const handleOpenScoringSelectMenu = (e: React.MouseEvent<HTMLElement>) =>
        setScoringSelectMenuAnchor(e.currentTarget);

    const handleErrorMarginChange = (value: number) => (e: React.MouseEvent) => {
        e.stopPropagation();
        setErrorMargin(value);
        setErrorMarginMenuAnchor(null);
    };

    const handleOpenErrorMarginMenu = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setErrorMarginMenuAnchor(e.currentTarget);
    };

    const handleCloseErrorMarginMenu = () => setErrorMarginMenuAnchor(null);

    const handleDownloadClick = async () => {
        showLoading();
        try {
            const response = await fetchScoringEvalData(selectedEvalId);
            const blob = response.ok ? await response.blob() : null;
            if (!blob) {
                throw new Error('Failed to download data');
            }
            saveAs(blob, `candidate-scoring-${moment().format('YYYY-MM-DD-hh-mm-ss')}.json`);
        } catch {
            setAlert('Error', 'Failed to download data');
        } finally {
            hideLoading();
        }
    };

    const handleSelectPerson = (personId: string, jobId: string) => () => {
        const list = sortedCandidates.map((c) => ({
            jobId: c.jobId,
            personId: c.personId
        }));
        const selected = {
            jobId,
            personId
        };
        setList(list, selected);
    };

    const handleColumnFilterChange = (filterMenuColumn: Column) => (selected: string[]) => {
        setFilters(
            selected.length === 0 ? filters.remove(filterMenuColumn) : filters.set(filterMenuColumn, Set(selected))
        );
        setPage(0);
    };

    const handleSortChange = (col: Column) => () => {
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('asc');
        }
    };

    const handleSetMetricsView = (view: 'stats' | 'table') => (e: React.MouseEvent) => {
        e.stopPropagation();
        setMetricsView(view);
    };

    const getProfileAdornments: AdornmentGenerator = React.useCallback(
        (personId: string, jobId?: string) => {
            if (!selectedEvalId) {
                return {};
            }
            const score = evalData?.eval?.profileScores?.find((s) => s.personId === personId && s.jobId === jobId);
            if (!score) {
                return {};
            }
            const scoreDetailedView = score ? (
                <div style={{ marginRight: '20px', position: 'relative' }}>
                    <AIProfileScore score={score} />
                </div>
            ) : null;
            const explanationDetailedView = <AIProfileScoreExplanation score={score} />;
            return {
                insertElements: [
                    { slot: 'explanationDetailedView', element: explanationDetailedView },
                    { slot: 'scoreDetailedView', element: scoreDetailedView }
                ]
            };
        },
        [evalData, selectedEvalId]
    );

    React.useEffect(() => {
        if (adornmentGeneratorSet || !getAdornmentGenerator()) {
            setAdornmentGenerator(getProfileAdornments);
            setAdornmentGeneratorSet(true);
        }
    }, [getProfileAdornments]);

    React.useEffect(() => {
        return () => {
            if (adornmentGeneratorSet) {
                setAdornmentGenerator(null);
            }
        };
    }, [adornmentGeneratorSet]);

    const headers = columns.map((col) => {
        const selected = filters.get(col)?.toArray() ?? [];
        const filterValues = sortBy(allCandidates?.map(valueFunc(col)) ?? []);
        const filterIcon =
            filterableColumns.indexOf(col) === -1 ? null : (
                <TableColumnFilterHeader
                    values={filterValues}
                    selected={selected}
                    onSelect={handleColumnFilterChange(col)}
                />
            );
        const sortIcon = sortAsc === 'desc' ? <ArrowDownward /> : <ArrowUpward />;
        const sort = (
            <span className={`column-action-icon ${sortCol === col ? 'visible' : ''}`} onClick={handleSortChange(col)}>
                {sortIcon}
            </span>
        );
        return (
            <TableCell key={col}>
                <span className="table-header-cell">
                    <span onClick={handleSortChange(col)}>{col}</span>
                    {sort}
                    {filterIcon}
                </span>
            </TableCell>
        );
    });

    let rows;

    if (allCandidates) {
        rows = sortedCandidates.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((record) => {
            const cells = columns.map((col) => {
                const value = valueFunc(col)(record);
                if (col === 'Last Stage Change') {
                    return <TableCell key={col}>{moment(value).format('MMM DD, YYYY')}</TableCell>;
                }
                if (col === 'Candidate') {
                    return (
                        <TableCell className="link-cell" onClick={handleSelectPerson(record.personId, record.jobId)}>
                            {record.candidate.person.name}
                        </TableCell>
                    );
                }
                if (col === 'Job') {
                    return (
                        <TableCell key={col} className="link-cell">
                            <PageDialogLink
                                Component={JobEdit}
                                componentProps={{ id: record.jobId }}
                                url={`/job/${record.jobId}/edit`}
                            >
                                {value}
                            </PageDialogLink>
                        </TableCell>
                    );
                }
                if (col === 'Progress Rating' || col === 'Score' || col === 'Delta') {
                    const val = typeof value === 'number' ? value.toFixed(1) : '-';
                    return <TableCell key={col}>{val}</TableCell>;
                }
                return <TableCell key={col}>{value}</TableCell>;
            });
            return <TableRow key={`${record.personId}-${record.jobId}`}>{cells}</TableRow>;
        });
    } else {
        rows = times(skeletonRowsCount).map((i) => (
            <TableRow key={i}>
                <TableCell colSpan={columns.length}>
                    <Skeleton variant="rect" />
                </TableCell>
            </TableRow>
        ));
    }

    const pagination =
        candidates === undefined ? (
            <div className="pagination-text">Loading...</div>
        ) : candidates.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={candidates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : (
            <div className="pagination-text">{candidates.length} Candidates</div>
        );

    const content = (
        <>
            <TableContainer className="table">
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>{headers}</TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
            <div className="table-footer">{pagination}</div>
        </>
    );

    const scoringsMenuItems = evalsData?.evals?.map((e) => {
        const icon = e.completedAt ? (
            <CheckCircleOutline fontSize="small" />
        ) : e.failedAt ? (
            <ErrorOutline fontSize="small" />
        ) : (
            <HourglassEmptyOutlined fontSize="small" />
        );
        return (
            <MenuItem key={e.id} value={e.id} onClick={handleSelectEval(e.id)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <Typography variant="inherit" noWrap={true}>
                    {e.title}
                </Typography>
                <IconButton size="small" onClick={handleViewScoringDetails(e.id)}>
                    <Info fontSize="small" />
                </IconButton>
            </MenuItem>
        );
    });
    const addScoringMenuItem = (
        <MenuItem value={createNewOpt} onClick={handleCreateScoringClick}>
            <ListItemIcon>
                <Add fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap={true}>
                Create New Scoring
            </Typography>
        </MenuItem>
    );
    const deselectMenuItem = selectedEvalId ? (
        <MenuItem value={null} onClick={handleSelectEval(null)}>
            <ListItemIcon>
                <Remove fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap={true}>
                Deselect
            </Typography>
        </MenuItem>
    ) : null;
    const scoringSelectMenu = (
        <Menu
            anchorEl={scoringSelectMenuAnchor}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={Boolean(scoringSelectMenuAnchor)}
            onClose={handleCloseMenu}
            css={menuStyles}
        >
            {deselectMenuItem}
            {scoringsMenuItems}
            {addScoringMenuItem}
        </Menu>
    );

    let form;
    const viewingEval = evalsData?.evals?.find((e) => e.id === viewingEvalId);
    if (viewingEval) {
        form = <CandidateScoreEvalForm data={viewingEval} open={true} onClose={handleCloseForm} />;
    } else if (creating) {
        form = <CandidateScoreEvalForm open={true} onClose={handleCloseForm} onSave={handleCreateEval} />;
    }

    let metrics;
    if (selectedEvalId && evalData?.eval) {
        const metricsData = getMetrics(
            progressScores,
            predictedScores,
            errorMargin,
            getAllStageScores(allCandidateScores)
        );
        let metricsContent;
        let errorMarginDisplay;
        if (metricsData) {
            const viewToggle = (
                <div>
                    <Tooltip title="View statistics for the scoring">
                        <Button
                            onClick={handleSetMetricsView('stats')}
                            className={metricsView !== 'stats' ? 'inactive' : ''}
                        >
                            <Poll />
                        </Button>
                    </Tooltip>
                    <Tooltip title="View confusion matrix for the scoring">
                        <Button
                            onClick={handleSetMetricsView('table')}
                            className={metricsView !== 'table' ? 'inactive' : ''}
                        >
                            <GridOn />
                        </Button>
                    </Tooltip>
                </div>
            );
            if (metricsView === 'stats') {
                const decimals = 3;
                const fields: Array<keyof ScoringMetrics> = ['weightedPrecision', 'weightedRecall'];
                const { statistics, scoringMetricsInfo, scoringMetricsLabels, scoringMetricsThresholds } = metricsData;
                const metricsRows = fields.map((key) => {
                    const value = statistics[key];
                    const thresholds = scoringMetricsThresholds[key];
                    const tooltip = (
                        <div>
                            <div>{scoringMetricsInfo[key]}</div>
                            <div>
                                Good: {thresholds.good} Bad: {thresholds.bad} Min: {thresholds.min} Max:{' '}
                                {thresholds.max}
                            </div>
                        </div>
                    );
                    return (
                        <TableRow key={key}>
                            <TableCell className="metric-label">
                                <span className="v-centered">
                                    {scoringMetricsLabels[key]}
                                    <Tooltip title={tooltip}>
                                        <Info fontSize="small" />
                                    </Tooltip>
                                </span>
                            </TableCell>
                            <TableCell className="metric-value">{value.toFixed(decimals)}</TableCell>
                            <TableCell className="metric-display">
                                <Tooltip title={tooltip}>
                                    <span>
                                        <MetricDisplay
                                            value={value}
                                            min={thresholds.min}
                                            max={thresholds.max}
                                            goodThreshold={thresholds.good}
                                            badThreshold={thresholds.bad}
                                        />
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    );
                });

                const errorMarginMenuItems = errorMarginOpts.map((opt) => (
                    <MenuItem key={opt} value={opt} onClick={handleErrorMarginChange(opt)}>
                        {opt.toFixed(1)}
                    </MenuItem>
                ));
                const errorMarginMenu = (
                    <Menu
                        anchorEl={errorMarginMenuAnchor}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        open={Boolean(errorMarginMenuAnchor)}
                        onClose={handleCloseErrorMarginMenu}
                        css={menuStyles}
                    >
                        {errorMarginMenuItems}
                    </Menu>
                );
                metricsContent = (
                    <Table className="stats-table">
                        <TableBody>{metricsRows}</TableBody>
                    </Table>
                );
                errorMarginDisplay = (
                    <div>
                        <Button onClick={handleOpenErrorMarginMenu}>Error Margin: {errorMargin}</Button>
                        {errorMarginMenu}
                    </div>
                );
            } else {
                const headerCols: JSX.Element[] = [];
                const matrixRows: JSX.Element[] = [];
                let rowIndex = 0;

                // Create a muted color scale from soft green (diagonal) to soft red (farthest from diagonal)
                // tslint:disable-next-line:no-magic-numbers
                const distanceColorScale = chroma.scale(['#a8d5a2', '#ffffff', '#b71c1c']).domain([0, 0.3, 1.0]);

                metricsData.confusionMatrix.forEach((value, rowKey) => {
                    headerCols.push(<TableCell key={`header-${rowKey}`}>{rowKey}</TableCell>);
                    let colIndex = 0;

                    const row: JSX.Element[] = [];
                    value.forEach((val, colKey) => {
                        // Check if the cell is on the diagonal
                        const distanceFromDiagonal = Math.abs(rowIndex - colIndex);

                        // Normalize the distance to a range of [0, 1]
                        const maxDistance = metricsData.confusionMatrix.size - 1;
                        const normalizedDistance = distanceFromDiagonal / maxDistance;

                        // Calculate RGB color from the color scale based on the normalized distance
                        const rgbColor = distanceColorScale(normalizedDistance).rgb();

                        // Determine the alpha value based on the cell value
                        const minAlpha = 0; // Minimum opacity (more transparent)
                        const maxAlpha = 1; // Maximum opacity (fully opaque)
                        // Adjust alpha dynamically based on cell value
                        const normalizedAlpha = Math.min(Math.max(val / 100, minAlpha), maxAlpha);

                        // Construct the RGBA color string
                        const cellColor = `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, ${normalizedAlpha})`;

                        row.push(
                            <TableCell
                                key={`cell-${rowKey}-${colKey}`}
                                style={{ backgroundColor: cellColor, color: '#000' }} // Apply color
                            >
                                {val.toFixed(2)}
                            </TableCell>
                        );
                        colIndex++;
                    });

                    matrixRows.push(
                        <TableRow key={`row-${rowKey}`}>
                            <TableCell>{rowKey}</TableCell>
                            {row}
                        </TableRow>
                    );
                    rowIndex++;
                });

                metricsContent = (
                    <Table className="matrix-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Actual/Prediction</TableCell>
                                {headerCols}
                            </TableRow>
                        </TableHead>
                        <TableBody>{matrixRows}</TableBody>
                    </Table>
                );
            }

            metrics = (
                <div className="metrics">
                    <ExpansionPanel defaultExpanded={true}>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <div className="statistics-header">
                                <Typography variant="h5">
                                    {metricsView === 'stats' ? 'Statistics' : 'Confusion Matrix'}
                                </Typography>
                                <div className="header-actions">
                                    {errorMarginDisplay}
                                    {viewToggle}
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>{metricsContent}</ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            );
        }
    }

    return (
        <DocumentTitle title="Candidate Scoring">
            <div id="container">
                <Header title="Candidate Scoring" />
                <div css={styles(theme)}>
                    <div className="selectors">
                        <Tooltip title="Download Data">
                            <Button onClick={handleDownloadClick} variant="outlined">
                                <GetApp />
                            </Button>
                        </Tooltip>
                        <Button onClick={handleOpenScoringSelectMenu} variant="outlined">
                            {selectedEval ? selectedEval.title : 'Select Scoring'}
                        </Button>
                    </div>
                    {metrics}
                    <div className="data-panel">{content}</div>
                </div>
                {form}
                {scoringSelectMenu}
            </div>
        </DocumentTitle>
    );
};
