import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { isEmail } from 'validator';
import { TextField } from '../core-ui/form-fields';

import { client as apolloClient } from '../graphql/apollo-client';
import { Customer, SAVE_BILL_CUSTOMER } from '../graphql/queries/billing';
import { useModal } from '../hooks/use-modal';
import { useFormContextData } from './form-context';

interface BillCustomerFormProps {
    clientId: string;
    open: boolean;
    onClose: (createdId: string | null) => void;
    customer?: Customer;
}

const styles = css`
    .MuiDialogContent-root {
        min-width: 640px;

        .form-row {
            display: flex;
            margin-bottom: 40px;
            gap: 24px;
        }
    }
`;

export const BillCustomerForm: React.FC<BillCustomerFormProps> = ({ open, clientId, customer, onClose }) => {
    const [saving, setSaving] = useState(false);
    const { setAlert } = useModal();
    const { data, onFieldChange, validateFieldExists, getError, setError } = useFormContextData(
        customer
            ? {
                  companyName: customer.companyName,
                  contact: customer.contact,
                  email: customer.email,
                  id: customer.id,
                  name: customer.name
              }
            : {
                  companyName: '',
                  contact: { firstName: '', lastName: '' },
                  email: '',
                  id: '',
                  name: ''
              }
    );
    const [saveBillCustomer] = useMutation<
        { saveBillCustomer: { id: string } },
        { customer: Customer; clientId: string }
    >(SAVE_BILL_CUSTOMER, {
        client: apolloClient('billing_admin')
    });

    const handleClose = () => {
        if (!saving) {
            onClose(null);
        }
    };

    const handleContactChange = (field: 'firstName' | 'lastName') => (val: string) => {
        onFieldChange('contact')({ ...data.contact, [field]: val });
    };

    const validateEmail = (email: string) => {
        if (!isEmail(email)) {
            setError('email', 'Please enter a valid email address');
            return false;
        } else {
            setError('email', undefined);
            return true;
        }
    };

    const validateContact = (field: 'firstName' | 'lastName') => () => {
        if (!data.contact[field]) {
            setError(`contact.${field}`, 'Required');
            return false;
        }
        setError(`contact.${field}`, undefined);
        return true;
    };

    const validate = () => {
        let valid = true;
        valid = validateFieldExists('name')(data.name) && valid;
        valid = validateEmail(data.email) && valid;
        valid = validateContact('firstName')() && valid;
        valid = validateContact('lastName')() && valid;
        return valid;
    };

    const handleSave = async () => {
        if (validate()) {
            try {
                setSaving(true);
                const result = await saveBillCustomer({ variables: { customer: data, clientId } });
                onClose(result.data?.saveBillCustomer?.id ?? null);
            } catch (error) {
                setAlert('Error', 'Failed to save customer');
            } finally {
                setSaving(false);
            }
        } else {
            setAlert('Error', 'Please correct the errors in the form');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} css={styles} maxWidth="md">
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {customer ? 'Edit Bill.com Customer' : 'Create Bill.com Customer'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="form-container">
                    <div className="form-row">
                        <TextField
                            label="Name"
                            value={data.name}
                            onChange={onFieldChange('name')}
                            validate={validateFieldExists('name')}
                            errorText={getError('name')}
                            disabled={saving}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Company Name"
                            value={data.companyName}
                            onChange={onFieldChange('companyName')}
                            disabled={saving}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Email"
                            value={data.email}
                            onChange={onFieldChange('email')}
                            validate={validateEmail}
                            errorText={getError('email')}
                            disabled={saving}
                        />
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Contact First Name"
                            value={data.contact.firstName}
                            onChange={handleContactChange('firstName')}
                            validate={validateContact('firstName')}
                            errorText={getError('contact.firstName')}
                            disabled={saving}
                        />
                        <TextField
                            label="Contact Last Name"
                            value={data.contact.lastName}
                            onChange={handleContactChange('lastName')}
                            validate={validateContact('lastName')}
                            errorText={getError('contact.lastName')}
                            disabled={saving}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={saving}>
                    Cancel
                </Button>
                <Button onClick={handleSave} disabled={saving}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
