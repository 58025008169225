import { gql } from '@apollo/client';

export interface AIInterviewRequest {
    id: string;
    interviewUrl: string;
    interviewTime: number;
    interviewTranscriptFile: string;
    interviewRecordingFile: string;
    requestId: string;
}

export const AI_INTERVIEW_REQUEST = gql`
    query AIInterviewRequest($personId: String!, $jobId: String!) {
        interviewRequests: ai_interview_requests(where: { personId: { _eq: $personId }, jobId: { _eq: $jobId } }) {
            id
            interviewUrl
            interviewTime
            interviewTranscriptFile
            interviewRecordingFile
            requestId
        }
    }
`;

export const CREATE_AI_INTERVIEW_REQUEST = gql`
    mutation CreateAIInterviewRequest($personId: String!, $jobId: String!) {
        createAIInterviewRequest(personId: $personId, jobId: $jobId)
    }
`;
