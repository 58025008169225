import { css } from '@emotion/core';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import * as React from 'react';

import { downloadFile } from '../actions';
import { tableBorderColor } from '../common/css-variables';
import { config } from '../config';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { Pdf } from './pdf';

const styles = css`
    .MuiDialog-paper {
        width: 70%;
    }
`;

export const FileDownloadLink: React.FC<{
    path: string;
    filename: string;
}> = ({ path, filename, children }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const pendingRequests = useReduxState((state) => state.pendingRequests);
    const dispatch = useReduxDispatch();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (path) {
            setDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleDownloadClick = () => {
        dispatch(downloadFile(path, filename));
    };

    const inProgress = pendingRequests.has(`download-file-${path}`);
    let dialog;
    if (dialogOpen) {
        const publicUrl = `${config.FilesHost}/${path.split('/').map(encodeURIComponent).join('/')}`;
        const actions = [
            <Button onClick={handleCloseDialog} key="close">
                Close
            </Button>,
            <Button onClick={handleDownloadClick} key="download" disabled={inProgress}>
                Download
            </Button>
        ];

        const preview = path.match(/\.pdf$/i) ? (
            <Pdf url={publicUrl} height="100%" width="100%" />
        ) : (
            <iframe
                src={`https://docs.google.com/viewer?embedded=true&url=${publicUrl}`}
                frameBorder="0"
                style={{ width: '100%', height: '100%' }}
            />
        );

        dialog = (
            <Dialog css={styles} open={true} onClose={handleCloseDialog} maxWidth="md">
                <div style={{ height: '75vh', margin: '0', borderBottom: `thin solid ${tableBorderColor}` }}>
                    {preview}
                </div>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }

    return (
        <div>
            <div className={`file-download-link ${inProgress || !path ? 'disabled' : ''}`} onClick={handleClick}>
                {children}
            </div>
            {dialog}
        </div>
    );
};
