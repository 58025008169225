import { useMutation } from '@apollo/client';
import React, { createContext, ReactNode, useContext, useState } from 'react';

import { JobFeeForm } from '../components/job-fee-form';
import { PlacementFeeForm } from '../components/placement-fee-form';
import { client as apolloClient } from '../graphql/apollo-client';
import { BaseFee, CREATE_JOB_FEE, DELETE_JOB_FEE, EDIT_JOB_FEE, Fee } from '../graphql/queries/billing';
import { BillingDataProvider, useBillingData } from './use-billing-data';
import { useModal } from './use-modal';
import { useSnackbar } from './use-snackbar';

interface FeesContextType {
    data: Fee[];
    disabled: boolean;
    onAddFee?: () => void;
    onEditFee?: (fee: BaseFee) => void;
    onDeleteFee?: (id: string) => void;
    onSelectFee?: (id: string) => void;
    refreshFees?: () => void;
}

const FeesContext = createContext<FeesContextType | undefined>(undefined);

interface FeesProviderProps {
    children: ReactNode;
    data: Fee[];
    getNewFeeInitialData?: () => BaseFee;
    refetch?: () => void;
    disabled: boolean;
    onSelectFee?: (id: string) => void;
}

export const FeesProvider = ({
    children,
    data,
    getNewFeeInitialData,
    refetch,
    disabled,
    onSelectFee
}: FeesProviderProps) => {
    const [viewingFeeId, setViewingFeeId] = useState<string | undefined>(undefined);
    const [addingFee, setAddingFee] = useState(false);
    const [savingFee, setSavingFee] = useState(false);
    const { setSnackbar } = useSnackbar();
    const { getConfirmation } = useModal();
    const [createFee] = useMutation<{}, { data: BaseFee }>(CREATE_JOB_FEE, { client: apolloClient('account_manager') });
    const [editFee] = useMutation<{}, { id: string; data: Omit<BaseFee, 'id'> }>(EDIT_JOB_FEE, {
        client: apolloClient('account_manager')
    });
    const [deleteFee] = useMutation<{}, { id: string }>(DELETE_JOB_FEE, {
        client: apolloClient('account_manager')
    });

    const handleAddFeeClick = getNewFeeInitialData
        ? () => {
              setAddingFee(true);
          }
        : undefined;

    const handleEditFee = (fee: BaseFee) => {
        setViewingFeeId(fee.id);
    };

    const handleCloseAddFeeDialog = () => {
        setViewingFeeId(undefined);
        setAddingFee(false);
    };

    const handleSaveFee = async (fee: BaseFee) => {
        setSavingFee(true);
        try {
            setSnackbar('Saving fee...');
            if (fee.id) {
                const { id, ...rest } = fee;
                await editFee({ variables: { id, data: { ...rest, dueAt: fee.dueAt ?? fee.incurredAt } } });
            } else {
                const dataToSave = {
                    ...getNewFeeInitialData(),
                    ...fee,
                    dueAt: fee.dueAt ?? fee.incurredAt
                };
                await createFee({ variables: { data: dataToSave } });
            }
            refetch?.();
            setViewingFeeId(undefined);
            setAddingFee(false);
            setSnackbar('Fee saved');
        } catch {
            setSnackbar('Failed to save fee');
        } finally {
            setSavingFee(false);
        }
    };

    const handleDeleteFee = (id: string) => {
        getConfirmation(
            async () => {
                try {
                    setSavingFee(true);
                    setSnackbar('Deleting fee...');
                    await deleteFee({ variables: { id } });
                    refetch?.();
                    setSnackbar('Fee deleted');
                } catch {
                    setSnackbar('Failed to delete fee');
                } finally {
                    setSavingFee(false);
                }
            },
            'Are you sure you want to delete this fee?',
            'Delete'
        );
    };

    let feeDialog;
    if (viewingFeeId || addingFee) {
        const fee = addingFee ? getNewFeeInitialData?.() : data.find((f) => f.id === viewingFeeId);
        feeDialog =
            fee?.type === 'placement' || fee?.type === 'monthly-contractor' ? (
                <PlacementFeeForm
                    data={fee}
                    onClose={handleCloseAddFeeDialog}
                    disabled={savingFee || disabled}
                    onSave={handleSaveFee}
                />
            ) : (
                <JobFeeForm
                    data={fee}
                    disabled={savingFee || disabled}
                    onSave={handleSaveFee}
                    onClose={handleCloseAddFeeDialog}
                />
            );
    }

    const contextValue: FeesContextType = {
        data,
        disabled,
        onAddFee: handleAddFeeClick,
        onDeleteFee: handleDeleteFee,
        onEditFee: handleEditFee,
        onSelectFee,
        refreshFees: refetch
    };

    const billingContext = useBillingData();

    const content = (
        <FeesContext.Provider value={contextValue}>
            {children}
            {feeDialog}
        </FeesContext.Provider>
    );

    // If we already have a refetch function from context, use the existing provider
    return billingContext.refetch ? content : <BillingDataProvider refetch={refetch}>{content}</BillingDataProvider>;
};

export const useFees = () => {
    const context = useContext(FeesContext);
    return context ?? { data: [], disabled: true };
};
