import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

import { feeTypeToService } from 'shared/models/fee';
import { CreateInvoiceData, FeeForInvoiceData, FeeForInvoiceData as FeeData } from 'shared/models/invoice';
import { defaultInvoiceNumber } from '../common/billing';
import { config } from '../config';
import { DateTextField, SelectField, TextField } from '../core-ui/form-fields';
import { Pdf } from '../core-ui/pdf';
import { client as apolloClient } from '../graphql/apollo-client';
import { CREATE_INVOICE, DELETE_INVOICE, Fee, SEND_INVOICE } from '../graphql/queries/billing';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';
import { useTimepicker } from '../hooks/use-time-picker';
import { BillCustomerSelector } from './bill-customer-selector';
import { useFormContextData } from './form-context';

const styles = css`
    .MuiDialogContent-root {
        min-width: 960px;
    }

    .form-row {
        margin-bottom: 40px;
        display: flex;
        gap: 24px;
        align-items: center;

        &.w9-checkbox {
            margin-bottom: 20px;
            margin-top: -20px;
        }

        .form-row-amount {
            width: 90px;
            flex: 0 0 auto;
        }

        .form-row-service {
            width: 240px;
            flex: 0 0 auto;
        }

        .form-row-period {
            width: 80px;
            flex: 0 0 auto;
        }

        .form-row-description {
            flex: 1 1 auto;
        }

        .MuiIconButton-root.remove-fee {
            width: 0;
            overflow: hidden;
            padding: 0;
            margin-left: -24px;
            transition:
                width 0.2s ease-in-out,
                margin-left 0.2s ease-in-out;
        }

        &:hover .MuiIconButton-root.remove-fee {
            width: 24px;
            margin-left: 0;
        }
    }
`;

const previewStyles = (theme: Theme) => css`
    .MuiDialog-paper {
        width: 70%;
    }

    .preview-pdf {
        height: 75vh;
        margin: 0;
        border-bottom: thin solid ${theme.palette.divider};
    }
`;

interface FeeErrors {
    [feeId: string]: {
        [K in Exclude<keyof FeeData, 'id'>]?: {
            message: string;
            isError: boolean;
        };
    };
}

export const CreateInvoiceForm: React.FC<{
    clientId: string;
    open: boolean;
    onClose: (created: boolean) => void;
    fees: Fee[];
}> = ({ clientId, fees: allFees, open, onClose }) => {
    const [saving, setSaving] = React.useState(false);
    const [previewingInvoice, setPreviewingInvoice] = React.useState<{ id: string; pdf: string } | null>(null);
    const [isInvoiceNumberEdited, setIsInvoiceNumberEdited] = React.useState(false);
    const { getCustomTime } = useTimepicker();

    const { getConfirmation, setAlert, showLoading, hideLoading } = useModal();
    const { setSnackbar } = useSnackbar();
    const theme = useTheme();
    const [saveInvoice] = useMutation<
        { createInvoice: { id: string; pdf: string } | { error: string } },
        { data: CreateInvoiceData }
    >(CREATE_INVOICE, {
        client: apolloClient('billing_admin')
    });
    const [deleteInvoice] = useMutation(DELETE_INVOICE, {
        client: apolloClient('billing_admin')
    });
    const [sendInvoice] = useMutation<{}, { invoiceId: string; sendAt: number }>(SEND_INVOICE, {
        client: apolloClient('billing_admin')
    });

    const fees = allFees.filter((f) => !f.appliedToFeeId);

    const { data, onFieldChange, getError, setError } = useFormContextData<CreateInvoiceData>({
        customerId: '',
        description: '',
        dueDate: Math.max(Date.now(), Math.min(...fees.map((f) => f.dueAt).filter(Boolean))) || Date.now(),
        fees: fees.map((fee) => {
            const description = [fee.job.title, fee.description, fee.person?.name].filter(Boolean).join(' - ');
            return { id: fee.id, description, service: feeTypeToService(fee.type), amount: fee.amount };
        }),
        includeW9: false,
        invoiceDate: Date.now(),
        invoiceNumber: defaultInvoiceNumber(fees),
        poNumber: ''
    });
    const [feeErrors, setFeeErrors] = React.useState<FeeErrors>({});

    // Update invoice number when fees change if not manually edited
    React.useEffect(() => {
        if (!isInvoiceNumberEdited) {
            const selectedFees = fees.filter((fee) => data.fees.some((f) => f.id === fee.id));
            onFieldChange('invoiceNumber')(defaultInvoiceNumber(selectedFees));
        }
    }, [data.fees, fees, isInvoiceNumberEdited, onFieldChange]);

    const handleInvoiceNumberChange = (value: string) => {
        setIsInvoiceNumberEdited(true);
        onFieldChange('invoiceNumber')(value);
    };

    const getFeePrepayments = (fee: Fee): FeeForInvoiceData[] => {
        const result: FeeForInvoiceData[] = [];
        for (const invoice of fee.invoices) {
            if (invoice.status === 'PAID_IN_FULL') {
                const lineItems = invoice.lineItems.filter((li) => li.feeId === fee.id);
                for (const lineItem of lineItems) {
                    result.push({
                        amount: -lineItem.amount,
                        description: `Prepayment Credit - ${invoice.invoiceNumber}`,
                        id: fee.id,
                        service: 'Prepayment Credit'
                    });
                }
            }
        }
        return result;
    };

    const getLineItemsForSelectedFees = (): FeeForInvoiceData[] => {
        const result = [];
        for (const fee of data.fees) {
            result.push(fee);
            const prepayments = getFeePrepayments(allFees.find((f2) => f2.id === fee.id));
            result.push(...prepayments);
        }
        return result;
    };

    const handleRemoveFee = (id: string) => () => {
        onFieldChange('fees')(data.fees.filter((f) => f.id !== id));
    };

    const validateFee =
        <T extends keyof FeeData>(feeId: string, field: T) =>
        (value: FeeData[T]) => {
            let error = { message: '', isError: false };

            switch (field) {
                case 'description':
                    if (!value?.toString().trim()) {
                        error = { message: 'Required', isError: true };
                    }
                    break;
                case 'service':
                    if (!value?.toString().trim()) {
                        error = { message: 'Required', isError: true };
                    }
                    break;
                case 'amount':
                    if (!value) {
                        error = { message: 'Required', isError: true };
                    } else if (Number(value) === 0) {
                        error = { message: 'Amount cannot be zero', isError: true };
                    } else {
                        const originalFee = fees.find((f) => f.id === feeId);
                        if (originalFee && originalFee.amount !== Number(value)) {
                            error = {
                                isError: false,
                                message: `Fee was ${originalFee.amount}`
                            };
                        }
                    }
                    break;
            }

            setFeeErrors((prev) => ({
                ...prev,
                [feeId]: {
                    ...prev[feeId],
                    [field]: error
                }
            }));

            return !error.isError;
        };

    const validateAllFees = () => {
        let valid = true;

        data.fees.forEach((fee) => {
            valid = validateFee(fee.id, 'description')(fee.description) && valid;
            valid = validateFee(fee.id, 'service')(fee.service) && valid;
            valid = validateFee(fee.id, 'amount')(fee.amount) && valid;
        });

        return valid;
    };

    const validate = () => {
        let valid = true;
        valid = validateCustomerId(data.customerId) && valid;
        valid = validateInvoiceNumber(data.invoiceNumber) && valid;
        valid = validateAllFees() && valid;
        return valid;
    };

    const validateCustomerId = (customerId: string) => {
        if (!customerId?.trim()) {
            setError('customerId', 'Required');
            return false;
        }
        setError('customerId', '');
        return true;
    };

    const validateInvoiceNumber = (invoiceNumber: string) => {
        const minLength = 5;
        const maxLength = 20;
        if (!invoiceNumber?.trim()) {
            setError('invoiceNumber', 'Required');
            return false;
        }
        if (invoiceNumber.length < minLength) {
            setError('invoiceNumber', `Must be at least ${minLength} characters`);
            return false;
        }
        if (invoiceNumber.length > maxLength) {
            setError('invoiceNumber', `Must be less than ${maxLength} characters`);
            return false;
        }
        setError('invoiceNumber', undefined);
        return true;
    };

    const handleClose = () => {
        if (!saving) {
            onClose(false);
        }
    };

    const handleCreate = async () => {
        if (validate()) {
            try {
                setSaving(true);
                showLoading();
                const dataWithPrepayments = { ...data, fees: getLineItemsForSelectedFees() };
                const result = await saveInvoice({ variables: { data: dataWithPrepayments } });
                if ((result.data.createInvoice as { error: string }).error) {
                    setAlert('Error', (result.data.createInvoice as { error: string }).error);
                    setSaving(false);
                } else {
                    setPreviewingInvoice(result.data.createInvoice as { id: string; pdf: string });
                }
            } catch {
                setSaving(false);
                setAlert('Error', 'Failed to create invoice');
            } finally {
                hideLoading();
            }
        } else {
            setAlert('Error', 'Please correct the errors in the form');
        }
    };

    const handleDeleteInvoice = async () => {
        getConfirmation(
            async () => {
                try {
                    showLoading();
                    setPreviewingInvoice(null);
                    await deleteInvoice({ variables: { id: previewingInvoice.id } });
                    setSaving(false);
                    setSnackbar('Invoice deleted');
                } catch {
                    setAlert('Error', 'Failed to delete invoice');
                } finally {
                    hideLoading();
                }
            },
            'Are you sure you want to delete this invoice?',
            'Delete Invoice'
        );
    };

    const handleSendInvoice = async (ts: number) => {
        try {
            showLoading();
            await sendInvoice({ variables: { invoiceId: previewingInvoice.id, sendAt: ts } });
            setPreviewingInvoice(null);
            onClose(true);
            setSnackbar('Invoice send scheduled');
        } catch {
            setAlert('Error', 'Failed to send invoice');
        } finally {
            hideLoading();
        }
    };

    const handleSendInvoiceNow = () => handleSendInvoice(Date.now());

    const handleSendInvoiceLater = () => {
        getCustomTime({
            buttonLabel: 'Schedule Send',
            onSelect: handleSendInvoice
        });
    };

    const handleW9Change = (_1: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onFieldChange('includeW9')(checked);
    };

    const handleFeeChange =
        <T extends keyof FeeData>(feeId: string, field: T) =>
        (value: FeeData[T]) => {
            onFieldChange('fees')(data.fees.map((f) => (f.id === feeId ? { ...f, [field]: value } : f)));
            validateFee(feeId, field)(value);
        };

    const serviceOptions = [
        { label: 'Monthly Recruiting', value: 'Monthly Recruiting' },
        { label: 'Candidate Placement', value: 'Candidate Placement' },
        { label: 'Container Fee', value: 'Container Fee' },
        { label: 'Retained Search Prepayment', value: 'Retained Search Prepayment' },
        { label: 'Retained Search Part 1', value: 'Retained Search Part 1' },
        { label: 'Retained Search Part 2', value: 'Retained Search Part 2' },
        { label: 'Monthly Contractor', value: 'Monthly Contractor' }
    ];

    const feeFields = data.fees.map((f) => {
        const errors = feeErrors[f.id] || {};
        const removeButton = saving ? null : (
            <IconButton onClick={handleRemoveFee(f.id)} disabled={saving} className="remove-fee">
                <Clear fontSize="small" />
            </IconButton>
        );
        const prepayments = getFeePrepayments(allFees.find((f2) => f2.id === f.id));
        const prepaymentFields = prepayments.map((p, index) => (
            <div className="form-row" key={`${f.id}-prepayment-${p.id}-${index}`}>
                <TextField label="Service" value={p.service} className="form-row-service" disabled={saving} />
                <TextField
                    label="Description"
                    value={p.description}
                    className="form-row-description"
                    disabled={saving}
                />
                <TextField
                    label="Amount"
                    value={p.amount}
                    className="form-row-amount"
                    type="number"
                    disabled={saving}
                />
            </div>
        ));
        const fee = allFees.find((f2) => f2.id === f.id);
        const periodField =
            fee.type === 'monthly-rpo' || fee.type === 'monthly-contractor' ? (
                <TextField
                    label="Period"
                    value={moment(fee.incurredAt).format('MMM YYYY')}
                    className="form-row-period"
                    disabled={saving}
                />
            ) : null;
        return (
            <React.Fragment key={f.id}>
                <div className="form-row" key={f.id}>
                    <SelectField
                        label="Service"
                        value={f.service}
                        options={serviceOptions}
                        className="form-row-service"
                        onChange={handleFeeChange(f.id, 'service')}
                        errorText={errors.service?.message}
                        error={errors.service?.isError}
                        disabled={saving}
                    />
                    {periodField}
                    <TextField
                        label="Description"
                        value={f.description}
                        className="form-row-description"
                        onChange={handleFeeChange(f.id, 'description')}
                        errorText={errors.description?.message}
                        error={errors.description?.isError}
                        disabled={saving}
                    />
                    <TextField
                        label="Amount"
                        value={f.amount}
                        className="form-row-amount"
                        onChange={handleFeeChange(f.id, 'amount')}
                        errorText={errors.amount?.message}
                        error={errors.amount?.isError}
                        type="number"
                        disabled={saving}
                    />
                    {removeButton}
                </div>
                {prepaymentFields}
            </React.Fragment>
        );
    });

    const total = data.fees.reduce((acc, f) => {
        const prepayments = getFeePrepayments(allFees.find((f2) => f2.id === f.id));
        return acc + f.amount + prepayments.reduce((acc2, p) => acc2 + p.amount, 0);
    }, 0);

    const createButton = data.fees.length ? (
        <Button disabled={saving} onClick={handleCreate}>
            Create
        </Button>
    ) : null;

    const checkbox = (
        <Checkbox
            checked={data.includeW9}
            onChange={handleW9Change}
            name="includeW9"
            color="primary"
            disabled={saving}
        />
    );

    const form = (
        <div className="form-container">
            <div className="form-row">
                <BillCustomerSelector
                    clientId={clientId}
                    value={data.customerId}
                    onSelect={onFieldChange('customerId')}
                    errorText={getError('customerId')}
                    disabled={saving}
                />
            </div>
            <div className="form-row w9-checkbox">
                <FormControlLabel control={checkbox} label="Include W9 form with the Invoice" />
            </div>
            <div className="form-row">
                <TextField
                    label="Invoice Number"
                    value={data.invoiceNumber}
                    onChange={handleInvoiceNumberChange}
                    errorText={getError('invoiceNumber')}
                    validate={validateInvoiceNumber}
                    disabled={saving}
                />
            </div>
            <div className="form-row">
                <DateTextField
                    label="Invoice Date"
                    value={data.invoiceDate}
                    onChange={onFieldChange('invoiceDate')}
                    disabled={saving}
                />
                <DateTextField
                    label="Due Date"
                    value={data.dueDate}
                    onChange={onFieldChange('dueDate')}
                    disabled={saving}
                />
            </div>
            {feeFields}
            <div className="form-row">
                <TextField label="Total" value={`${data.fees.length} fees`} disabled={saving} />
                <TextField
                    label="Amount"
                    value={`$${total.toLocaleString()}`}
                    className="form-row-amount"
                    disabled={saving}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Message"
                    value={data.description}
                    onChange={onFieldChange('description')}
                    disabled={saving}
                    placeholder="Enter an optional message to include in the invoice"
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Purchase Order Number"
                    value={data.poNumber}
                    onChange={onFieldChange('poNumber')}
                    placeholder="Enter an optional PO number to include in the invoice"
                    disabled={saving}
                />
            </div>
        </div>
    );

    const noFees = data.fees.length === 0 ? 'No fees to create invoices for' : null;

    let previewDialog;
    if (previewingInvoice) {
        const publicUrl = `${config.FilesHost}/${previewingInvoice.pdf.split('/').map(encodeURIComponent).join('/')}`;
        previewDialog = (
            <Dialog css={previewStyles(theme)} open={true} maxWidth="md">
                <div className="preview-pdf">
                    <Pdf url={publicUrl} height="100%" width="100%" />
                </div>
                <DialogActions>
                    <Button onClick={handleDeleteInvoice}>Delete</Button>
                    <Button onClick={handleSendInvoiceLater}>Send Later</Button>
                    <Button onClick={handleSendInvoiceNow}>Send Now</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog open={open} onClose={handleClose} css={styles} maxWidth="lg">
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Create Invoice
                </Typography>
            </DialogTitle>
            <DialogContent>{data.fees.length ? form : noFees}</DialogContent>
            <DialogActions>
                <Button disabled={saving} onClick={handleClose}>
                    {data.fees.length ? 'Cancel' : 'Close'}
                </Button>
                {createButton}
            </DialogActions>
            {previewDialog}
        </Dialog>
    );
};
