import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import {
    AccessTime,
    AttachMoney,
    CheckCircleOutline,
    Close,
    Edit,
    ErrorOutline,
    HourglassEmpty,
    Person,
    Work
} from '@material-ui/icons';
import React from 'react';

import { feeTypeLabel } from 'shared/models/fee';
import { hasRole } from 'shared/models/user';

import { feeStatus, getFeeStatusLabel } from '../common/billing';
import { PageDialogLink } from '../common/page-dialog-link';
import { fullDate } from '../common/timestamp';
import { JobEdit } from '../containers/job-edit';
import { Fee as FeeData } from '../graphql/queries/billing';
import { useSlides } from '../hooks/use-candidate-slides';
import { useFees } from '../hooks/use-fees';
import { useSession } from '../hooks/use-session';

export const Fee: React.FC<{ fee: FeeData }> = ({ fee }) => {
    const { setList } = useSlides();
    const { user, userPermissions } = useSession();
    const { onDeleteFee, onEditFee, disabled, onSelectFee } = useFees();

    const canEdit =
        !disabled &&
        ((hasRole(userPermissions, 'account_manager') && fee.accountManager?.id === user.id) ||
            hasRole(userPermissions, 'billing_admin'));

    const handleFeeClick = () => {
        if (
            fee.type === 'placement' ||
            fee.type === 'monthly-rpo' ||
            fee.type === 'prepayment' ||
            fee.type === 'monthly-contractor'
        ) {
            onEditFee?.(fee);
        }
    };

    const handleCandidateClick = () => {
        const candidate = { personId: fee.person.id, jobId: fee.job.id };
        setList([candidate], candidate);
    };

    const handleDeleteFee = () => {
        onDeleteFee?.(fee.id);
    };

    const handleSelectFee = () => {
        onSelectFee?.(fee.id);
    };

    let primaryText = feeTypeLabel(fee.type);
    if (fee.person) {
        primaryText = `${primaryText} - ${fee.person.name}`;
    }

    const description = fee.description ? (
        <span>
            {fee.description}
            <br />
        </span>
    ) : null;
    const secondaryText = fee.job ? (
        <span>
            {description}${fee.amount.toLocaleString()}
            <br />
            {fee.job.title}
            <br />
            {fullDate(fee.incurredAt)}
        </span>
    ) : (
        <span>
            {description}${fee.amount.toLocaleString()}
            <br />
            {fullDate(fee.incurredAt)}
        </span>
    );

    const status = feeStatus(fee);

    const statusIcon = {
        invoice_not_due_yet: <AccessTime />,
        invoice_not_found: <ErrorOutline />,
        invoice_over_amount: <ErrorOutline />,
        invoice_under_amount: <ErrorOutline />,
        paid_in_full: <AttachMoney />,
        waiting_for_payment: <HourglassEmpty />
    }[status];

    const statusIndicator = (
        <Tooltip title={getFeeStatusLabel(status)}>
            <IconButton onClick={handleFeeClick} className={status === 'paid_in_full' ? 'show-on-hover' : undefined}>
                {statusIcon}
            </IconButton>
        </Tooltip>
    );

    const detailsInfoIcon = fee.person ? (
        <Tooltip title="View Candidate">
            <IconButton onClick={handleCandidateClick} className="show-on-hover">
                <Person />
            </IconButton>
        </Tooltip>
    ) : fee.job ? (
        <PageDialogLink Component={JobEdit} componentProps={{ id: fee.job.id }} url={`/job/${fee.job.id}`}>
            <Tooltip title="View Job">
                <IconButton className="show-on-hover">
                    <Work />
                </IconButton>
            </Tooltip>
        </PageDialogLink>
    ) : null;

    const editButtons = canEdit ? (
        <>
            <Tooltip title="Delete">
                <IconButton className="show-on-hover" onClick={handleDeleteFee}>
                    <Close />
                </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
                <IconButton className="show-on-hover" onClick={handleFeeClick}>
                    <Edit />
                </IconButton>
            </Tooltip>
        </>
    ) : null;

    const selectFeeButton = onSelectFee ? (
        <Tooltip title="Select Fee">
            <IconButton className="show-on-hover" onClick={handleSelectFee}>
                <CheckCircleOutline />
            </IconButton>
        </Tooltip>
    ) : null;

    return (
        <ListItem key={fee.id} button={true} onClick={handleFeeClick}>
            <ListItemText primary={primaryText} secondary={secondaryText} />
            <ListItemSecondaryAction>
                {editButtons}
                {detailsInfoIcon}
                {selectFeeButton}
                {statusIndicator}
            </ListItemSecondaryAction>
        </ListItem>
    );
};
