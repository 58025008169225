// cspell: ignore MMMYY
import { startCase } from 'lodash';
import moment from 'moment';

import { InvoiceStatus } from 'shared/models/invoice';

import { BaseFee, Fee, Invoice } from '../graphql/queries/billing';

type FeeStatus =
    | 'invoice_not_found'
    | 'invoice_under_amount'
    | 'invoice_over_amount'
    | 'waiting_for_payment'
    | 'paid_in_full'
    | 'invoice_not_due_yet';

export const getFeeStatusLabel = (status: FeeStatus): string => {
    return startCase(status);
};

export const feeStatus = (fee: BaseFee & { invoices?: Invoice[] }): FeeStatus => {
    const feeAllInvoicesAmount = fee.invoices?.reduce((acc, i) => acc + i.totalAmount, 0);
    const paidAmount = fee.invoices
        ?.map((i) => i.payments.filter((p) => p.status === 'PAID'))
        .reduce((acc, p) => {
            return acc + p.reduce((acc2, l2) => acc2 + l2.amount, 0);
        }, 0);
    const feeInvoicedAmount = fee.invoices?.reduce(
        (acc, i) => acc + i.lineItems.filter((l) => l.feeId === fee.id).reduce((acc2, l) => acc2 + l.amount, 0),
        0
    );
    const status =
        fee.amount === 0
            ? 'paid_in_full'
            : !fee.invoices?.find((i) => i.lineItems.find((l) => l.feeId === fee.id))
              ? fee.dueAt > Date.now()
                  ? 'invoice_not_due_yet'
                  : 'invoice_not_found'
              : feeInvoicedAmount < fee.amount
                ? 'invoice_under_amount'
                : feeInvoicedAmount > fee.amount
                  ? 'invoice_over_amount'
                  : paidAmount !== feeAllInvoicesAmount
                    ? 'waiting_for_payment'
                    : 'paid_in_full';

    return status;
};

type InvoiceStatusExtended = 'FEE_NOT_FOUND' | 'OVERDUE' | InvoiceStatus;

export const getInvoiceStatusLabel = (status: InvoiceStatusExtended): string => {
    return startCase(status.toLocaleLowerCase());
};

export const invoiceStatus = (invoice: Invoice): InvoiceStatusExtended => {
    const notAppliedFees = invoice.lineItems.find((lineItem) => !lineItem.feeId);
    const status = notAppliedFees
        ? 'FEE_NOT_FOUND'
        : invoice.status !== 'PAID_IN_FULL' && invoice.dueDate < Date.now()
          ? 'OVERDUE'
          : invoice.status;
    return status;
};

function sanitizeForInvoiceNumber(input: string): string {
    return startCase(input.toLocaleLowerCase())
        .replace(/[^a-zA-Z0-9\s]/g, '') // Remove special characters except spaces
        .replace(/\s+/g, '-'); // Replace spaces with dashes
}

export function defaultInvoiceNumber(fees: Fee[]): string {
    const minLength = 5;
    const maxLength = 20;
    if (!fees.length) return '';

    const dueDate = moment(fees[0].dueAt);
    const monthYearSuffix = dueDate.format('MMMYY');
    const clientName = sanitizeForInvoiceNumber(fees[0].client.name);
    let result = '';

    if (fees.length === 1 && fees[0].type === 'placement' && fees[0].person?.name) {
        // For a single placement fee, use person name
        const placementName = sanitizeForInvoiceNumber(fees[0].person.name);
        result = placementName;

        if (result.length > maxLength) {
            result = placementName.slice(0, maxLength);
        }
    } else if (fees.length === 1 && fees[0].type === 'monthly-contractor' && fees[0].person?.name) {
        // For a single monthly contractor fee, use person name and month
        const contractorName = sanitizeForInvoiceNumber(fees[0].person.name);
        const availableNameLength = maxLength - monthYearSuffix.length - 1;

        result = contractorName;
        if (result.length > availableNameLength) {
            result = contractorName.slice(0, availableNameLength);
        }
        result = `${result}-${monthYearSuffix}`;
    } else if (fees.every((fee) => fee.type === 'monthly-contractor' || fee.type === 'monthly-rpo')) {
        // If all fees are monthly contractor or monthly RPO, use client name and month
        const monthlyPrefix = 'M-';

        result = clientName;
        if (result.length > maxLength - monthlyPrefix.length - monthYearSuffix.length - 1) {
            result = clientName.slice(0, maxLength - monthlyPrefix.length - monthYearSuffix.length - 1);
        }
        result = `${monthlyPrefix}${result}-${monthYearSuffix}`;
    } else {
        // For all other cases, use client name and month
        result = clientName;
        if (result.length > maxLength - monthYearSuffix.length - 1) {
            result = clientName.slice(0, maxLength - monthYearSuffix.length - 1);
        }
        result = `${result}-${monthYearSuffix}`;
    }
    if (result.length < minLength) {
        result = result.padEnd(minLength, '0');
    }
    return result;
}
