import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Checkbox,
    Chip,
    Drawer,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    useTheme
} from '@material-ui/core';
import {
    AccountCircle,
    Archive,
    Block,
    Computer,
    DateRange,
    FindInPage,
    LocalLibrary,
    LocalOffer,
    LocationOn,
    Pause,
    PeopleAlt,
    RemoveFromQueue,
    Schedule,
    School,
    Star,
    SupervisorAccount,
    ViewCarousel,
    Work
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import _, { flatten } from 'lodash';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { JobStatus } from 'shared/models/job';
import { personVisaOptionLabels, VisaStatus } from 'shared/models/person';
import { UserData } from 'shared/models/user';
import {
    Filters,
    filtersOrder,
    getCompany,
    getDiversityValue,
    getProgressStage,
    getProgressTimestamp,
    getSchoolRank
} from 'shared/types/candidates-search';

import { replaceHistory } from '../common/history-replace';
import { idealGroups } from '../common/job-disciplines';
import { logger } from '../common/logger';
import { Loading } from '../core-ui/loading';
import { CANDIDATES_SEARCH, CandidatesSearchVariables, PersonRecord } from '../graphql/queries/candidates-search';
import { ALL_CLIENTS, Client } from '../graphql/queries/clients';
import { ALL_JOB_TITLES, JobTitleData } from '../graphql/queries/job';
import { ACTIVE_USERS, UPDATE_USER, User } from '../graphql/queries/user';
import { useSlides } from '../hooks/use-candidate-slides';
import { useModal } from '../hooks/use-modal';
import { AdornmentGenerator, useProfileAdornments } from '../hooks/use-profile-adornment';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { CandidatesSearchAddActions } from './candidates-search-add-action';
import { CandidatesSearchFilters } from './candidates-search-filters';
import { Header } from './header';
import { LoadFilters } from './load-filters';
import { renderPersonTagOptions } from './person-tags';
import { SaveFilter } from './save-filters';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
        flex: 0 0 auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .search-actions {
            min-width: fit-content;
        }

        .filters {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            max-height: 132px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
            }
        }

        .MuiButton-outlined {
            background: white;
            margin-left: 15px;

            &.filter {
                min-width: 48px;
                padding: 9px 4px;

                i.fas {
                    font-size: 16px;
                }
            }
        }
    }

    .field-presets-spacer {
        border-right: thin solid rgba(0, 0, 0, 0.3);

        &:last-child {
            border-right: none;
        }
    }

    .candidates-table {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .MuiTableRow-root {
            cursor: pointer;
            &:hover {
                background-color: ${theme.palette.action.hover};
            }
            &:last-child {
                .MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }
        }

        .MuiTableCell-root {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .check-box-column {
            width: 20px;
        }

        .table-status-message {
            padding: 80px;
            text-align: center;
            text-transform: uppercase;
            font-size: 13px;
        }

        .table-pagination {
            border-top: thin solid ${theme.palette.divider};
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }

        .table-footer {
            text-align: right;
        }

        .pagination {
            border-top: thin solid ${theme.palette.divider};
        }
    }
`;

const jobTitleStyle = css`
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;

    .MuiSvgIcon-root {
        margin-left: 3px;
        opacity: 0.8;
    }
`;

const rowsPerPage = 25;

interface CandidateSearchProps {
    initFilters: Filters;
    redirect?: () => void;
    userPage?: boolean;
    title: string;
}

export const CandidatesSearch: React.FC<CandidateSearchProps> = (props) => {
    const theme = useTheme();
    const userId = useSession().user.id;
    const { setList } = useSlides();
    const { setSnackbar } = useSnackbar();
    const snackBarDurationMs = 3000;
    const { setAlert } = useModal();
    const { setAdornmentGenerator } = useProfileAdornments();

    const { initFilters, userPage, title, redirect } = props;

    const [filtersChanged, setFiltersChanged] = React.useState(false);
    const [filtersDrawerOpen, setFiltersDrawerOpen] = React.useState(true);
    const [selectedPersons, setSelectedPersons] = React.useState<string[]>([]);
    const [persons, setPersons] = React.useState<PersonRecord[]>(undefined);
    const [page, setPage] = React.useState(0);
    const [selectedPersonRecords, setSelectedPersonRecords] = React.useState<PersonRecord[]>([]);
    const [searchOnLoad, setSearchOnLoad] = React.useState<boolean>(false);

    const [filters, setFilters] = React.useState<Filters>(initFilters);

    const { data: userData, refetch } = useQuery<{ users: User[] }>(ACTIVE_USERS);
    const [search, { loading, error }] = useLazyQuery<{ persons: PersonRecord[] }, CandidatesSearchVariables>(
        CANDIDATES_SEARCH,
        {
            fetchPolicy: 'cache-and-network',
            onCompleted(data) {
                setPersons(data?.persons);
            }
        }
    );

    const [updateUser, { loading: updating }] = useMutation<{}, { id: string; updates: Partial<UserData> }>(
        UPDATE_USER
    );

    const { data: jobsData } = useQuery<{ jobs: JobTitleData[] }, { status: number[] }>(ALL_JOB_TITLES);
    const jobTitles: { [id: string]: { label: JSX.Element; text: string } } = React.useMemo(
        () =>
            jobsData?.jobs.reduce((acc, j) => {
                const statusIcon =
                    j.status === JobStatus.Archived ? (
                        <Archive fontSize="small" />
                    ) : j.status === JobStatus.Paused ? (
                        <Pause fontSize="small" />
                    ) : null;
                const label = (
                    <span css={jobTitleStyle}>
                        {j.client.name} - {j.title} {statusIcon}
                    </span>
                );
                const text = `${j.client.name} - ${j.title}`;
                return { ...acc, [j.id]: { label, text } };
            }, {}),
        [jobsData]
    );

    const jobIds = React.useMemo(() => jobsData?.jobs.map((j) => j.id), [jobsData]);

    const { data: clientsData } = useQuery<{ clients: Client[] }>(ALL_CLIENTS);

    const handleSelectPerson = React.useCallback(
        (id: string) => (_1: React.ChangeEvent, selected: boolean) => {
            setSelectedPersons((prev) => (selected ? prev.concat([id]) : prev.filter((personId) => id !== personId)));
        },
        []
    );

    const getProfileAdornments: AdornmentGenerator = React.useCallback(
        (personId: string) => ({
            insertElements: [
                {
                    element: (
                        <Checkbox
                            checked={selectedPersons.includes(personId)}
                            onChange={handleSelectPerson(personId)}
                        />
                    ),
                    slot: 'select'
                }
            ]
        }),
        [selectedPersons, handleSelectPerson]
    );

    React.useEffect(() => {
        if (persons?.length) {
            setAdornmentGenerator(getProfileAdornments);
            return () => setAdornmentGenerator(null);
        }
    }, [persons, getProfileAdornments]);

    React.useEffect(() => {
        if (jobsData && userData && clientsData) {
            try {
                const qs = new URLSearchParams(window.location.search);
                const qsFilters = JSON.parse(qs.get('filters'));
                if (qsFilters) {
                    setFilters(qsFilters);
                    setFiltersChanged(true);
                }
            } catch (err) {
                logger.error(err, { message: `Error parsing filters from querystring ${window.location.search}` });
            }
        }
    }, [jobsData, userData]);

    React.useEffect(() => {
        if (userPage) {
            setFiltersDrawerOpen(false);
            handleSearch();
        }
    }, []);

    React.useEffect(() => {
        if (searchOnLoad && filtersChanged && !filtersDrawerOpen) {
            handleSearch();
            setSearchOnLoad(false);
        }
    }, [searchOnLoad]);

    const handleFilterSave = async (name: string) => {
        setSnackbar('Saving...');
        try {
            const userFilters = userData.users.find((f) => f.id === userId).candidatesSearchFilters;
            await updateUser({
                variables: {
                    id: userId,
                    updates: { candidatesSearchFilters: userFilters.concat({ name: name.trim(), filters }) }
                }
            });
            refetch();
            setSnackbar('Filter Saved', undefined, snackBarDurationMs);
        } catch (err) {
            setSnackbar('Error Saving');
            logger.error(err, { message: 'Error saving candidates search presets' });
        }
    };

    const handleFilterDelete = async (index: number) => {
        setSnackbar('Deleting...');
        try {
            const userFilters = userData.users.find((f) => f.id === userId).candidatesSearchFilters;
            await updateUser({
                variables: {
                    id: userId,
                    updates: { candidatesSearchFilters: userFilters.filter((f) => userFilters.indexOf(f) !== index) }
                }
            });
            refetch();
            setSnackbar('Filter Deleted', undefined, snackBarDurationMs);
        } catch (err) {
            setSnackbar('Error Deleting Filter');
            logger.error(err, { message: 'Error deleting candidates search presets' });
        }
    };

    const handleFilterSelect = (index: number) => {
        const updated = userData.users.find((f) => f.id === userId).candidatesSearchFilters[index].filters;
        const currentUrl = window.location.pathname;
        const qs = encodeURIComponent(JSON.stringify(updated));
        replaceHistory({}, '', `${currentUrl}?filters=${qs}`);
        setFilters(updated);
        setFiltersChanged(true);
        setPersons(undefined);
        setPage(0);
        setSelectedPersons([]);
        setFiltersDrawerOpen(false);
        setSearchOnLoad(true);
    };

    const handleToggleFilters = () => {
        if (userPage) {
            redirect();
        } else {
            setFiltersDrawerOpen(!filtersDrawerOpen);
        }
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        // no-op
    };

    const handleFilterChange = (updates: Partial<Filters>) => {
        const updated = { ...filters, ...updates };
        const currentUrl = window.location.pathname;
        const qs = encodeURIComponent(JSON.stringify(updated));
        replaceHistory({}, '', `${currentUrl}?filters=${qs}`);
        setFilters(updated);
        setFiltersChanged(true);
        setPersons(undefined);
        setPage(0);
        setSelectedPersons([]);
    };

    const handleSelectAll = () => {
        if (persons?.length === selectedPersons?.length) {
            setSelectedPersons([]);
        } else {
            setSelectedPersons(persons?.map((record) => record.person.id));
        }
    };

    const handleRowClick = (id: string) => (event: React.SyntheticEvent) => {
        event.preventDefault();
        setList(
            persons?.map((record) => ({ personId: record.person.id })),
            { personId: id }
        );
    };

    const handleSearch = () => {
        setFiltersChanged(false);
        setPersons(undefined);

        if (!needFilters && !needSpecificJobFilters && !needProfileFilters && !needJobActivityFilters) {
            const variables: CandidatesSearchVariables = {
                assignees: filters.assignees?.length > 0 ? filters.assignees : null,
                blacklistedOnly: !!filters.blacklistedOnly,
                csDegreeOnly: !!filters.csDegreeOnly,
                currentCompany: getCompany(filters.currentCompany),
                disciplines: filters.disciplines?.length > 0 ? filters.disciplines : null,
                diversityGender: getDiversityValue(filters.diversity, 'Female') ? 'female' : null,
                diversityLGBTQ: getDiversityValue(filters.diversity, 'LGBTQ') ? true : null,
                diversityURM: getDiversityValue(filters.diversity, 'URM') ? true : null,
                diversityVeteran: getDiversityValue(filters.diversity, 'Veteran') ? true : null,
                earliestTs: getProgressTimestamp(filters.timeWindow),
                excludedClients: filters.excludedClients?.length > 0 ? filters.excludedClients : null,
                favoritesOnly: !!filters.favoritesOnly,
                keywords: filters.keywords?.length > 0 ? filters.keywords : null,
                locations: filters.locations?.length > 0 ? filters.locations : null,
                minYearsExp: filters.minYearsExp.length > 0 ? parseInt(filters.minYearsExp, 10).toString() : null,
                profileGroup: filters.profileGroup.length > 0 ? filters.profileGroup : null,
                schoolRank: getSchoolRank(filters.schoolRank),
                specificJobs: (filters.specificJobs ?? []).length > 0 ? filters.specificJobs : null,
                stageProgress: getProgressStage(filters.progress),
                tags: filters.tags?.length > 0 ? filters.tags.map((t) => t.toLowerCase()) : null,
                userId,
                visas: filters.visas?.length > 0 ? filters.visas : null
            };
            search({ variables });
        }
    };

    const handleChipDelete =
        <T extends keyof Filters>(field: T, value: Filters[T]) =>
        () => {
            if (field === 'timeWindow') {
                handleFilterChange({ progress: '', timeWindow: '' });
            } else if (field === 'disciplines') {
                const map = flatten((value as string[]).map((d) => idealGroups[d]));
                const profileGroup = filters.profileGroup.filter((t) => map.indexOf(t) !== -1);
                handleFilterChange({ profileGroup, disciplines: value as string[] });
            } else {
                handleFilterChange({ [field]: value });
            }
            setFiltersDrawerOpen(true);
        };

    React.useEffect(() => {
        if (selectedPersons) {
            setSelectedPersonRecords(persons?.filter((f) => selectedPersons.includes(f.person.id)));
        }
    }, [selectedPersons]);

    React.useEffect(() => {
        if (!filtersDrawerOpen && filtersChanged) {
            handleSearch();
        }
    }, [filtersDrawerOpen]);

    React.useEffect(() => {
        if (!loading && error) {
            setAlert('Search Error', 'Error running search with selected filters, please report to the product team.');
        }
    }, [error]);

    const filterIcons: { [Prop in keyof Filters]: JSX.Element } = {
        assignees: <AccountCircle />,
        blacklistedOnly: <Block />,
        csDegreeOnly: <Computer />,
        currentCompany: <Work />,
        disciplines: <LocalLibrary />,
        diversity: <i className="fas fa-handshake" />,
        excludedClients: <RemoveFromQueue />,
        favoritesOnly: <Star />,
        keywords: <FindInPage />,
        locations: <LocationOn />,
        minYearsExp: <DateRange />,
        profileGroup: <SupervisorAccount />,
        progress: <ViewCarousel />,
        schoolRank: <School />,
        specificJobs: <PeopleAlt />,
        tags: <LocalOffer />,
        timeWindow: <Schedule />,
        visas: <i className="fas fa-id-card" />
    };

    if (!jobsData || !userData || !clientsData) {
        return <Loading />;
    }

    // multiline js expression inside JSX fix
    const arrChipsDelete = <T extends keyof Filters>(field: T, value: string) => {
        return (filters[field] as string[]).filter((f) => f !== value);
    };

    const boolFieldLabel = <T extends keyof Filters>(field: T) => {
        const label =
            field === 'favoritesOnly' ? 'Favorites' : field === 'blacklistedOnly' ? 'Blacklisted' : 'CS Degree';
        return label;
    };

    const filterChips = filtersOrder.map((field) => {
        let chips: JSX.Element | JSX.Element[];
        if (field === 'blacklistedOnly' || field === 'favoritesOnly' || field === 'csDegreeOnly') {
            chips = filters[field] ? (
                <Chip
                    key={field}
                    icon={filterIcons[field]}
                    label={boolFieldLabel(field)}
                    onDelete={handleChipDelete(field, false)}
                />
            ) : null;
        } else if (
            field === 'visas' ||
            field === 'assignees' ||
            field === 'tags' ||
            field === 'disciplines' ||
            field === 'locations' ||
            field === 'currentCompany' ||
            field === 'specificJobs' ||
            field === 'diversity' ||
            field === 'keywords' ||
            field === 'profileGroup' ||
            field === 'excludedClients'
        ) {
            chips =
                filters[field].length > 0
                    ? filters[field].map((value) => {
                          const label =
                              field === 'visas'
                                  ? personVisaOptionLabels.get(value as VisaStatus)
                                  : field === 'specificJobs'
                                    ? jobTitles[value].label
                                    : field === 'tags'
                                      ? renderPersonTagOptions(value)
                                      : field === 'assignees'
                                        ? userData.users.filter((f) => f.id === value)[0].name.full
                                        : field === 'excludedClients'
                                          ? clientsData.clients.filter((f) => f.id === value)[0].name
                                          : value;
                          return (
                              <Chip
                                  key={value}
                                  icon={filterIcons[field]}
                                  label={label}
                                  onDelete={handleChipDelete(field, arrChipsDelete(field, value))}
                              />
                          );
                      })
                    : null;
        } else if (
            field === 'progress' ||
            field === 'timeWindow' ||
            field === 'schoolRank' ||
            field === 'minYearsExp'
        ) {
            chips = filters[field] ? (
                <Chip
                    key={field}
                    icon={filterIcons[field]}
                    label={field === 'minYearsExp' ? `Min ${filters[field]} Years of Experience` : filters[field]}
                    onDelete={handleChipDelete(field, '')}
                />
            ) : null;
        }
        return chips ? (
            <React.Fragment key={field}>
                {chips}
                <div className="field-presets-spacer" />
            </React.Fragment>
        ) : null;
    });

    const filtersDrawer =
        userData && jobsData ? (
            <Drawer open={filtersDrawerOpen} anchor="right" onClose={handleToggleFilters}>
                <CandidatesSearchFilters
                    clients={clientsData.clients}
                    users={userData.users}
                    filters={filters}
                    onChange={handleFilterChange}
                    onSearch={handleToggleFilters}
                    jobs={{ ids: jobIds, titles: jobTitles }}
                />
            </Drawer>
        ) : null;

    const columns = ['Name', 'Current Company', 'Title', 'Location', 'Years of Experience'];
    const headers = columns.map((c) => <TableCell key={c}>{c}</TableCell>);
    const rows = persons
        ? persons.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((record) => {
              return (
                  <TableRow key={record.person.id}>
                      <TableCell className="check-box-column">
                          <Checkbox
                              checked={selectedPersons.includes(record.person.id)}
                              onChange={handleSelectPerson(record.person.id)}
                          />
                      </TableCell>
                      <TableCell onClick={handleRowClick(record.person.id)}>
                          <a href={`/person/${record.person.id}`} target="_blank">
                              {record.person.name}
                          </a>
                      </TableCell>
                      <TableCell onClick={handleRowClick(record.person.id)}>{record.currentCompany}</TableCell>
                      <TableCell onClick={handleRowClick(record.person.id)}>{record.currentTitle}</TableCell>
                      <TableCell onClick={handleRowClick(record.person.id)}>{record.currentLocation}</TableCell>
                      <TableCell onClick={handleRowClick(record.person.id)}>
                          {parseFloat(record.totalExperience).toFixed(2)}
                      </TableCell>
                  </TableRow>
              );
          })
        : loading
          ? columns.map((_1, i) => (
                <TableRow key={i}>
                    <TableCell colSpan={columns.length + 1}>
                        <Skeleton variant="rect" />
                    </TableCell>
                </TableRow>
            ))
          : null;

    const needJobActivityFilters =
        (filters.timeWindow.length !== 0 && filters.progress.length === 0) ||
        (filters.timeWindow.length === 0 && filters.progress.length !== 0)
            ? true
            : false;

    const needProfileFilters =
        (filterChips.filter((c) => c !== null) ?? []).length > 0 &&
        filters.assignees.length === 0 &&
        filters.blacklistedOnly === false &&
        filters.favoritesOnly === false &&
        filters.progress === '' &&
        filters.tags.length === 0 &&
        filters.timeWindow === '' &&
        filters.visas.length === 0 &&
        filters.specificJobs.length === 0
            ? true
            : false;

    const needSpecificJobFilters =
        (filters.timeWindow === '' || filters.progress === '') && filters.specificJobs.length > 0 ? true : false;

    const needFilters =
        filters.assignees.length === 0 &&
        filters.blacklistedOnly === false &&
        filters.csDegreeOnly === false &&
        filters.currentCompany.length === 0 &&
        filters.disciplines.length === 0 &&
        filters.diversity.length === 0 &&
        filters.favoritesOnly === false &&
        filters.locations.length === 0 &&
        filters.progress === '' &&
        filters.schoolRank === '' &&
        filters.specificJobs.length === 0 &&
        filters.tags.length === 0 &&
        filters.timeWindow === '' &&
        filters.visas.length === 0
            ? true
            : false;

    const statusMessage = loading ? undefined : needJobActivityFilters ? (
        <div className="table-status-message">Specify both Activity Time Window and Candidate Progress Filters</div>
    ) : needProfileFilters || needSpecificJobFilters ? (
        <div className="table-status-message">Add Additional Filters - Search Too Broad</div>
    ) : filtersChanged && !persons && (filterChips.filter((f) => f !== null) ?? []).length > 0 ? (
        <div className="table-status-message">Click Search to update results</div>
    ) : !persons && filtersChanged && (filterChips.filter((f) => f !== null) ?? []).length === 0 ? (
        <div className="table-status-message">No Candidates Found</div>
    ) : !persons ? (
        <div className="table-status-message">Add Filters To Search</div>
    ) : undefined;

    const pagination =
        persons === undefined ? undefined : persons.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={persons.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : (
            <div className="table-pagination">{persons.length} Candidates</div>
        );

    const footer = statusMessage === undefined ? <div className="table-footer">{pagination}</div> : undefined;

    const partialAllSelected = selectedPersons?.length > 0 && selectedPersons?.length !== persons?.length;
    const allSelected = selectedPersons?.length > 0 && selectedPersons?.length === persons?.length;
    const disableAdd = selectedPersons?.length > 0 ? false : true;

    const handleAddClose = (value: 'notes' | 'jobs') => {
        setSelectedPersons([]);
        const message = value === 'notes' ? 'Notes created' : 'Candidates added to Job';
        setSnackbar(message, undefined, snackBarDurationMs);
    };

    const selectedFiltersCount = filterChips.filter((group) => group !== null).length;
    const filterActions = userPage ? null : (
        <>
            <LoadFilters
                list={userData.users.find((f) => f.id === userId).candidatesSearchFilters}
                onDelete={handleFilterDelete}
                onSelect={handleFilterSelect}
                disabled={loading || updating}
            />
            <SaveFilter onSave={handleFilterSave} disabled={loading || updating || selectedFiltersCount === 0} />
        </>
    );

    return (
        <DocumentTitle title={title}>
            <div id="container">
                <Header title={title} />
                <div css={styles(theme)}>
                    <div className="header">
                        <div className="filters">{filterChips}</div>
                        <div className="search-actions">
                            {filterActions}
                            <CandidatesSearchAddActions
                                disabled={disableAdd}
                                persons={selectedPersonRecords}
                                dialogClose={handleAddClose}
                            />
                            <Button
                                variant="outlined"
                                disabled={!filtersChanged || (filterChips.filter((f) => f !== null) ?? []).length === 0}
                                onClick={handleSearch}
                                hidden={userPage}
                            >
                                Search
                            </Button>
                            <Button className="filter" variant="outlined" onClick={handleToggleFilters}>
                                <i className="fas fa-filter" />
                            </Button>
                        </div>
                    </div>
                    <div className="candidates-table">
                        <TableContainer className="table">
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="check-box-column">
                                            <Checkbox
                                                indeterminate={partialAllSelected}
                                                checked={allSelected}
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>
                                        {headers}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{rows}</TableBody>
                            </Table>
                        </TableContainer>
                        {statusMessage}
                        {footer}
                    </div>
                </div>
                {filtersDrawer}
            </div>
        </DocumentTitle>
    );
};
